import React from 'react';

import { useFilePicker, UseFilePickerProps } from '@lumapps/utils/hooks/useFilePicker';

import { SelectedFile } from '../ducks/slice';

export interface UseUploadFileProps {
    /** options for the file picker */
    filePickerOptions?: Partial<UseFilePickerProps>;
    /** input id for accessibility purposes */
    inputId: string;
    /** Function executed when files have been selected */
    onSelect?: (files: SelectedFile[]) => void;
}

export interface UseUploadFile {
    /** callback to be executed once the media wants to be edited */
    onEdit: () => void;
    /** function that opens up the file picker */
    openPicker: () => void;
    /** input to be added to the DOM in order to display the picker */
    hiddenInput: React.JSX.Element;
}

/**
 * Hook that centralises the actions for uploading a file.
 * @param {UseUploadFileProps}
 * @return {UseUploadFile}
 */
export const useUploadFile = ({ filePickerOptions, inputId, onSelect }: UseUploadFileProps): UseUploadFile => {
    /**
     * After the file select, we update the internal state of the hook.
     * This function is used by the useFilePicker.
     */
    const onSelectFiles = async (files: File[]) => {
        if (files.length) {
            const selectedFiles: SelectedFile[] = [];

            files.forEach((file) => {
                const blobUrl = URL.createObjectURL(file);

                selectedFiles.push({
                    blobUrl,
                    file,
                });
            });

            onSelect?.(selectedFiles);
        }
    };

    const { hiddenInput, openPicker } = useFilePicker({
        ...filePickerOptions,
        onSelectFiles,
        inputProps: {
            id: inputId,
            ...filePickerOptions?.inputProps,
        },
    });

    /**
     * When choosing another file we open up the picker once more and let the normal flow go by
     */
    const onEdit = () => {
        openPicker();
    };

    return {
        onEdit,
        openPicker,
        hiddenInput,
    };
};
