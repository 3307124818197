import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import { SelectButton } from '@lumapps/combobox/components/SelectButton/SelectButton';
import { getPermissionsForCustomContentType } from '@lumapps/content-types/ducks/selectors';
import { CUSTOM_CONTENT_TYPE_PERMISSIONS } from '@lumapps/content-types/permissions';
import { ContentStatusFlag } from '@lumapps/contents/components/ContentStatusFlag';
import {
    getCurrentContentProperties,
    getCurrentContentLastPublicationDate,
    getCurrentContentPublicationStatus,
    getCurrentContentTitle,
    getCurrentContentUpdatedByDetails,
    getCurrentContentUpdatedDate,
    getCurrentContributionMode,
    getCurrentCustomContentTypeDetails,
    getCurrentCustomContentTypeId,
    getCurrentContentScheduledDatesSelector,
} from '@lumapps/contents/ducks/selectors';
import { useDataAttributes } from '@lumapps/data-attributes';
import { contributionLanguagesSelector, inputLanguageSelector } from '@lumapps/languages';
import { actions } from '@lumapps/languages/ducks/slice';
import { LANGUAGES } from '@lumapps/languages/keys';
import {
    mdiArchiveArrowUpOutline,
    mdiArrowRight,
    mdiArrowUp,
    mdiAutorenew,
    mdiClose,
    mdiContentSave,
    mdiTranslate,
    mdiTune,
} from '@lumapps/lumx/icons';
import { Button, ButtonGroup, FlexBox, Heading, IconButton } from '@lumapps/lumx/react';
import { useDispatch, useSelector } from '@lumapps/redux/react';
import { useResponsive } from '@lumapps/responsive';
import { getKeyForLanguage, GLOBAL, useTranslate } from '@lumapps/translations';

import { TOPBAR_CLASSNAME, CONTRIBUTION_MODES, CONTENT_EDITOR_TOPBAR_SCOPE } from '../../constants';
import { DESIGNER } from '../../keys';
import { LegacyContentSettingsDialogVariant } from '../../types';
import { ContentStatusMetadata } from './ContentStatusMetadata';
import { SecondaryActionsMenu } from './SecondaryActionsMenu';

import './index.scss';

export interface ContentEditorTopbarProps {
    /** Callback called to open content settings */
    onSettingsOpen: (variant?: LegacyContentSettingsDialogVariant) => void;
    /** Callback called to save a content as draft */
    saveContent: () => void;
    /** Callback called when the archive button is clicked */
    archiveContent: () => void;
    /** Callback called when the close button is clicked. */
    closeContentEditor: () => void;
    /** Callback called when the unarchive button is clicked. */
    unarchiveContent: () => void;
    /** Callback called when the submit for review button is clicked. */
    submitForReview: () => void;
    /** Callback called when the request revision button is clicked. */
    requestRevision: () => void;
    /** Callback called when the accept revision button is clicked. */
    acceptRevision: () => void;
    /** Callback called to set a content to draft status. */
    setContentToDraft: () => void;
}

/**
 *
 * @param ContentEditorTopbarProps
 * @returns ContentEditorTopbar
 */
export const ContentEditorTopbar: React.FC<ContentEditorTopbarProps> = ({
    onSettingsOpen,
    saveContent,
    archiveContent,
    unarchiveContent,
    closeContentEditor,
    submitForReview,
    acceptRevision,
    requestRevision,
    setContentToDraft,
}) => {
    const { get } = useDataAttributes(CONTENT_EDITOR_TOPBAR_SCOPE);
    const { element } = useClassnames(TOPBAR_CLASSNAME);
    const { translateKey } = useTranslate();
    const { isDesktop } = useResponsive();

    const { hasPendingDraft, isAwaitingValidation, isRevisionRequested, publicationStatus } = useSelector(
        getCurrentContentPublicationStatus,
    );

    const titleObject = useSelector(getCurrentContentTitle);
    const lastPublicationDate = useSelector(getCurrentContentLastPublicationDate);
    const { startDate: scheduledDate, endDate: expirationDate } = useSelector(getCurrentContentScheduledDatesSelector);
    const customContentTypeDetails = useSelector(getCurrentCustomContentTypeDetails);
    const updatedAt = useSelector(getCurrentContentUpdatedDate);
    const updatedByDetails = useSelector(getCurrentContentUpdatedByDetails);
    const contentProperties = useSelector(getCurrentContentProperties);
    const currentContributionMode = useSelector(getCurrentContributionMode);
    const customContentType = useSelector(getCurrentCustomContentTypeId);
    const isNewDraft = publicationStatus === 'draft' && !updatedAt;
    const refusedReason = contentProperties?.publicationError;

    const dispatch = useDispatch();
    const setInputLang = React.useCallback((lang) => dispatch(actions.updateLocaleInputLanguage({ lang })), [dispatch]);
    const isWorkflowEnabled = customContentTypeDetails?.isWorkflowEnabled;
    const contributionLanguages = useSelector(contributionLanguagesSelector);
    const inputLanguage = useSelector(inputLanguageSelector);

    const title = titleObject?.[inputLanguage];
    const contentTypeAuthorizations = useSelector(getPermissionsForCustomContentType(customContentType || ''));
    const canSave = currentContributionMode !== CONTRIBUTION_MODES.READER && publicationStatus !== 'archived';

    const canArchive =
        contentTypeAuthorizations.includes(CUSTOM_CONTENT_TYPE_PERMISSIONS.CUSTOM_CONTENT_ARCHIVE) && !isNewDraft;
    const canUnarchive = publicationStatus === 'archived' && canArchive;

    const canPublish = isWorkflowEnabled
        ? contentTypeAuthorizations.includes(CUSTOM_CONTENT_TYPE_PERMISSIONS.CUSTOM_CONTENT_PUBLISH)
        : contentTypeAuthorizations.includes(CUSTOM_CONTENT_TYPE_PERMISSIONS.CUSTOM_CONTENT_EDIT);

    const quickAction = React.useMemo(() => {
        if (isWorkflowEnabled) {
            if (!isAwaitingValidation) {
                return {
                    label: translateKey(DESIGNER.SUBMIT_FOR_REVIEW_LABEL),
                    onClick: submitForReview,
                    shortIcon: mdiArrowRight,
                    props: {
                        ...get({ element: 'publication-workflow', action: 'submit-for-review' }),
                    },
                };
            }

            return {
                label: translateKey(DESIGNER.PUBLISH_CHANGES_LABEL),
                onClick: acceptRevision,
                shortIcon: mdiAutorenew,
                props: {
                    ...get({ element: 'publication-workflow', action: 'publish-changes' }),
                },
            };
        }

        if (publicationStatus !== 'draft' && publicationStatus !== 'expired') {
            return {
                label: translateKey(DESIGNER.PUBLISH_CHANGES_LABEL),
                onClick: () => onSettingsOpen(LegacyContentSettingsDialogVariant.review),
                shortIcon: mdiAutorenew,
                props: {
                    ...get({ element: 'publication-workflow', action: 'publish-changes' }),
                },
            };
        }

        return {
            label: translateKey(GLOBAL.PUBLISH),
            onClick: isAwaitingValidation
                ? acceptRevision
                : () => onSettingsOpen(LegacyContentSettingsDialogVariant.review),
            shortIcon: mdiArrowUp,
            props: {
                ...get({ element: 'publication-workflow', action: 'publish' }),
            },
        };
    }, [
        acceptRevision,
        get,
        isAwaitingValidation,
        isWorkflowEnabled,
        onSettingsOpen,
        publicationStatus,
        submitForReview,
        translateKey,
    ]);

    return (
        <FlexBox
            role="toolbar"
            aria-labelledby="toolbar-title"
            aria-describedby="status-flag status-metadata"
            className={element('wrapper')}
            noShrink
            hAlign="center"
            gap="big"
            orientation="horizontal"
            vAlign="space-between"
        >
            <IconButton
                label={translateKey(GLOBAL.CLOSE)}
                onClick={closeContentEditor}
                style={{ flexShrink: 0 }}
                emphasis="low"
                icon={mdiClose}
                {...get({ action: 'close' })}
            />
            <FlexBox gap="tiny" orientation="vertical" style={{ overflow: 'hidden' }}>
                <FlexBox gap="medium" orientation="horizontal" hAlign="center">
                    {title ? (
                        <Heading
                            id="toolbar-title"
                            className={element('title')}
                            truncate={{ lines: 2 }}
                            typography="subtitle1"
                        >
                            {title}
                        </Heading>
                    ) : (
                        <Heading
                            id="toolbar-title"
                            truncate={{ lines: 2 }}
                            typography="subtitle1"
                            color="dark"
                            colorVariant="L3"
                        >
                            {translateKey(GLOBAL.UNTITLED)}
                        </Heading>
                    )}
                    <ContentStatusFlag id="status-flag" aria-live="polite" status={publicationStatus} />
                    <SelectButton
                        className={element('language-switcher')}
                        emphasis="low"
                        size="s"
                        getOptionId={(option) => option}
                        getOptionName={(option) => translateKey(getKeyForLanguage(option))}
                        leftIcon={mdiTranslate}
                        label={translateKey(LANGUAGES.LANGUAGE_SWITCHER_ARIA_LABEL)}
                        options={contributionLanguages}
                        value={inputLanguage}
                        onChange={setInputLang}
                        {...get({ action: 'select-language' })}
                    />
                </FlexBox>

                <ContentStatusMetadata
                    updatedBy={updatedByDetails}
                    refusedReason={refusedReason}
                    updatedAt={updatedAt}
                    hasPendingDraft={hasPendingDraft}
                    expirationDate={expirationDate}
                    scheduledDate={scheduledDate}
                    publicationDate={lastPublicationDate}
                    publicationStatus={publicationStatus}
                    isAwaitingValidation={isAwaitingValidation}
                    isRevisionRequested={isRevisionRequested}
                />
            </FlexBox>
            <FlexBox gap="regular" noShrink marginAuto="left" orientation="horizontal">
                {isDesktop ? (
                    <IconButton
                        label={translateKey(GLOBAL.SETTINGS)}
                        emphasis="low"
                        icon={mdiTune}
                        onClick={() => onSettingsOpen(LegacyContentSettingsDialogVariant.settings)}
                        {...get({ element: 'publication-options', action: 'open' })}
                    />
                ) : (
                    <Button
                        emphasis="low"
                        leftIcon={mdiTune}
                        onClick={() => onSettingsOpen(LegacyContentSettingsDialogVariant.settings)}
                        {...get({ element: 'publication-options', action: 'open' })}
                    >
                        {translateKey(GLOBAL.SETTINGS)}
                    </Button>
                )}

                {canSave &&
                    (isDesktop ? (
                        <IconButton
                            label={translateKey(GLOBAL.SAVE)}
                            emphasis="medium"
                            icon={mdiContentSave}
                            onClick={saveContent}
                            {...get({ element: 'publication-workflow', action: 'save-as-draft' })}
                        />
                    ) : (
                        <Button
                            emphasis="medium"
                            onClick={saveContent}
                            {...get({ element: 'publication-workflow', action: 'save-as-draft' })}
                        >
                            {translateKey(GLOBAL.SAVE)}
                        </Button>
                    ))}
                {canUnarchive &&
                    (isDesktop ? (
                        <IconButton
                            label={translateKey(GLOBAL.UNARCHIVE)}
                            emphasis="medium"
                            icon={mdiArchiveArrowUpOutline}
                            onClick={unarchiveContent}
                            {...get({ element: 'publication-workflow', action: 'unarchive' })}
                        />
                    ) : (
                        <Button
                            emphasis="medium"
                            onClick={unarchiveContent}
                            {...get({ element: 'publication-workflow', action: 'unarchive' })}
                        >
                            {translateKey(GLOBAL.UNARCHIVE)}
                        </Button>
                    ))}
                {publicationStatus !== 'archived' && (
                    <ButtonGroup>
                        {isDesktop ? (
                            <IconButton
                                label={quickAction.label}
                                onClick={quickAction.onClick}
                                emphasis="high"
                                icon={quickAction.shortIcon}
                                {...quickAction.props}
                            />
                        ) : (
                            <Button onClick={quickAction.onClick} emphasis="high" {...quickAction.props}>
                                {quickAction.label}
                            </Button>
                        )}
                        <SecondaryActionsMenu
                            archiveContent={archiveContent}
                            publicationStatus={publicationStatus}
                            canArchive={canArchive}
                            isAwaitingValidation={isAwaitingValidation}
                            isSavedOnce={!isNewDraft}
                            canPublish={canPublish}
                            requestRevision={requestRevision}
                            setContentToDraft={setContentToDraft}
                            publishContent={() => onSettingsOpen(LegacyContentSettingsDialogVariant.review)}
                        />
                    </ButtonGroup>
                )}
            </FlexBox>
        </FlexBox>
    );
};
