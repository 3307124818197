import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import { get } from '@lumapps/constants';
import { ContentPreviewButton } from '@lumapps/content-preview/components/ContentPreviewButton';
import { newTemplateFromContentRoute } from '@lumapps/content-templates/routes';
import { canUserDesignContentType } from '@lumapps/content-types/ducks/selectors';
import { isContent } from '@lumapps/content-types/utils';
import {
    getCurrentContentId,
    getCurrentContentPublicationStatus,
    getCurrentContentStatus,
    getCurrentContentType,
    getCurrentContributionMode,
    getCurrentCustomContentTypeId,
    isContributionAlphaFeatureEnabled,
} from '@lumapps/contents/ducks/selectors';
import { CONTENTS } from '@lumapps/contents/keys';
import { contentCopy } from '@lumapps/contents/routes';
import { LegacyContentStatus, type Content } from '@lumapps/contents/types';
import { useDataAttributes } from '@lumapps/data-attributes';
import { Menubar } from '@lumapps/lumx-menu/components/Menubar';
import {
    mdiContentDuplicate,
    mdiHistory,
    mdiMenu,
    mdiTranslate,
    mdiTrashCanOutline,
    mdiViewCompactOutline,
} from '@lumapps/lumx/icons';
import { GenericBlock, Icon, Text } from '@lumapps/lumx/react';
import { isInstanceSuperAdmin } from '@lumapps/permissions';
import { useSelector } from '@lumapps/redux/react';
import { useRouter } from '@lumapps/router';
import { getKeyForLanguage, GLOBAL, useTranslate } from '@lumapps/translations';

import { CONTRIBUTION_MODE_MAP, CONTRIBUTION_MODES, SELECTABLE_CONTRIBUTION_MODES } from '../../constants';
import { DESIGNER } from '../../keys';

import './index.scss';

export interface ContentEditorContextualActionsProps {
    /**
     * Save the current content draft.
     */
    saveContent: () => Promise<Content>;
    /**
     * Set the current contribution mode.
     */
    setContributionMode: (contributionMode: CONTRIBUTION_MODES, shouldStore?: boolean) => void;
    /**
     * Enable the versioning mode.
     */
    enableVersioningMode: () => void;
    /**
     * Add the current content to the navigation.
     */
    addToNavigation: () => void;
    /**
     * Delete the current content.
     */
    deleteContent: () => void;
    /**
     * The callback executed when a lang is selected in the Translation menu
     */
    onTranslate: (targetLang: string) => void;
    /**
     * Whether the user has the right to delete the current content.
     */
    canDelete?: boolean;
}

const SCOPE = 'content-editor-contextual-actions';
const CLASSNAME = 'content-editor-contextual-actions';
const constants = get();

/**
 *
 * @param ContentEditorContextualActionsProps
 * @returns ContentEditorContextualActions
 */
export const ContentEditorContextualActions: React.FC<ContentEditorContextualActionsProps> = ({
    saveContent,
    setContributionMode,
    enableVersioningMode,
    addToNavigation,
    deleteContent,
    onTranslate,
    canDelete,
}) => {
    const isContributionAlphaEnabled = useSelector(isContributionAlphaFeatureEnabled);
    const { get: getDataAttributes } = useDataAttributes(SCOPE);
    const { block } = useClassnames(CLASSNAME);
    const { translateKey } = useTranslate();

    const { publicationStatus, isAwaitingValidation } = useSelector(getCurrentContentPublicationStatus);
    const legacyStatus = useSelector(getCurrentContentStatus);
    const contentType = useSelector(getCurrentContentType);
    const customContentType = useSelector(getCurrentCustomContentTypeId);
    const contentId = useSelector(getCurrentContentId);

    const isPublished = publicationStatus === 'published';
    const isScheduled = publicationStatus === 'scheduled';
    const isArchived = publicationStatus === 'archived';

    /** Whether the content has been saved once. */
    const isSavedOnce = Boolean(contentId);

    const isAdmin = useSelector(isInstanceSuperAdmin);

    // Contribution mode
    const currentContributionMode = useSelector(getCurrentContributionMode) || CONTRIBUTION_MODES.READER;
    const currentContributionModeProps = CONTRIBUTION_MODE_MAP[currentContributionMode] || {};
    const onContributionModeChange = React.useCallback(
        (contributionMode: CONTRIBUTION_MODES) => {
            setContributionMode(contributionMode, true);
        },
        [setContributionMode],
    );
    const isReadModeOn = currentContributionMode === CONTRIBUTION_MODES.READER;

    const canChangeContributionMode =
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        useSelector(canUserDesignContentType(customContentType!)) && !isArchived && !isReadModeOn;

    // Translate
    const contributionLanguages = constants.userContributionLangs;
    const canTranslate = contributionLanguages.length > 1 && isContributionAlphaEnabled && false;

    // Duplicate
    const { redirect } = useRouter();
    const copyRoute = contentCopy(contentId, legacyStatus || LegacyContentStatus.draft);

    // Set as template
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const setAsTemplateRoute = newTemplateFromContentRoute(contentId, customContentType!);
    const canSetAsTemplate = isAdmin && isContent(contentType);

    const canSeeHistory = !isArchived && isSavedOnce && !isReadModeOn;

    // Nav
    const canAddToNavigation = isAdmin && (isPublished || isScheduled);

    // Preview is not available for archived contents
    const canPreview = !isArchived;

    // View history mode is matching this.
    if (isReadModeOn && !isAwaitingValidation) {
        return null;
    }

    return (
        <div className={block()}>
            <Menubar ariaLabel="contextual actions" orientation="vertical">
                <Menubar.Section>
                    {canPreview && <ContentPreviewButton saveContentDraft={saveContent} />}
                    {canTranslate && (
                        <Menubar.ItemSubmenu
                            tooltipProps={{
                                placement: 'left',
                            }}
                            icon={mdiTranslate}
                            triggerProps={{
                                popoverProps: {
                                    offset: { away: 6, along: 6 },
                                },
                            }}
                            label={translateKey(CONTENTS.CONTEXTUAL_ACTIONS_TRANSLATION_OFF_ACTION)}
                        >
                            {contributionLanguages.map((lang) => {
                                return (
                                    <Menubar.Item
                                        key={lang}
                                        onClick={() => onTranslate(lang)}
                                        {...getDataAttributes({ action: 'translate' })}
                                    >
                                        {translateKey(getKeyForLanguage(lang))}
                                    </Menubar.Item>
                                );
                            })}
                        </Menubar.ItemSubmenu>
                    )}
                </Menubar.Section>

                {canChangeContributionMode && (
                    <Menubar.Section>
                        <Menubar.ItemSubmenu
                            icon={currentContributionModeProps.icon}
                            tooltipProps={{
                                placement: 'left',
                            }}
                            triggerProps={{
                                popoverProps: {
                                    offset: { away: 6, along: 6 },
                                },
                            }}
                            label={translateKey(currentContributionModeProps.key)}
                        >
                            <Menubar.SectionRadioGroup
                                value={currentContributionMode}
                                onChange={onContributionModeChange}
                            >
                                {(
                                    Object.keys(
                                        SELECTABLE_CONTRIBUTION_MODES,
                                    ) as (keyof typeof SELECTABLE_CONTRIBUTION_MODES)[]
                                ).map((contribModeKey) => {
                                    const { icon, description, key } =
                                        SELECTABLE_CONTRIBUTION_MODES[contribModeKey] ?? {};

                                    return (
                                        <Menubar.ItemRadio
                                            aria-label={translateKey(key)}
                                            key={key}
                                            value={contribModeKey}
                                            truncate={false}
                                            {...getDataAttributes({
                                                element: 'contribution-mode-select',
                                                action: `switch-to-${contribModeKey}`,
                                            })}
                                        >
                                            <GenericBlock as="span">
                                                {icon && (
                                                    <GenericBlock.Figure as="span">
                                                        <Icon icon={icon} size="xs" />
                                                    </GenericBlock.Figure>
                                                )}
                                                <GenericBlock.Content as="span">
                                                    <Text as="span" typography="body1" id="copy-link-label">
                                                        {translateKey(key)}
                                                    </Text>
                                                    {description && (
                                                        <Text
                                                            as="span"
                                                            typography="caption"
                                                            color="dark"
                                                            colorVariant="L2"
                                                        >
                                                            {translateKey(description)}
                                                        </Text>
                                                    )}
                                                </GenericBlock.Content>
                                            </GenericBlock>
                                        </Menubar.ItemRadio>
                                    );
                                })}
                            </Menubar.SectionRadioGroup>
                        </Menubar.ItemSubmenu>
                    </Menubar.Section>
                )}
                <Menubar.Section>
                    {canSeeHistory && (
                        <Menubar.Item
                            tooltipProps={{ placement: 'left' }}
                            icon={mdiHistory}
                            onClick={enableVersioningMode}
                            {...getDataAttributes({ action: 'show-version-history' })}
                        >
                            {translateKey(DESIGNER.SHOW_VERSION_HISTORY_LABEL)}
                        </Menubar.Item>
                    )}
                    {canAddToNavigation && (
                        <Menubar.Item
                            tooltipProps={{ placement: 'left' }}
                            icon={mdiMenu}
                            onClick={addToNavigation}
                            tooltipLabel={translateKey(DESIGNER.ADD_TO_NAVIGATION_TOOLTIP)}
                            {...getDataAttributes({ action: 'add-to-navigation' })}
                        >
                            {translateKey(DESIGNER.ADD_TO_NAVIGATION)}
                        </Menubar.Item>
                    )}
                    <Menubar.Item
                        tooltipProps={{ placement: 'left' }}
                        icon={mdiContentDuplicate}
                        isDisabled={!isSavedOnce}
                        onClick={() => redirect(copyRoute, { openInNewTab: true })}
                        {...getDataAttributes({ action: 'duplicate-content' })}
                    >
                        {translateKey(GLOBAL.DUPLICATE)}
                    </Menubar.Item>
                    {canSetAsTemplate && (
                        <Menubar.Item
                            tooltipProps={{ placement: 'left' }}
                            icon={mdiViewCompactOutline}
                            isDisabled={!isSavedOnce}
                            onClick={() => redirect(setAsTemplateRoute, { openInNewTab: true })}
                            {...getDataAttributes({ action: 'set-as-template' })}
                        >
                            {translateKey(DESIGNER.SET_AS_TEMPLATE)}
                        </Menubar.Item>
                    )}
                </Menubar.Section>
                <Menubar.Section>
                    {canDelete && (
                        <Menubar.Item
                            tooltipProps={{ placement: 'left' }}
                            icon={mdiTrashCanOutline}
                            onClick={deleteContent}
                            {...getDataAttributes({ action: 'delete' })}
                        >
                            {translateKey(GLOBAL.DELETE)}
                        </Menubar.Item>
                    )}
                </Menubar.Section>
            </Menubar>
        </div>
    );
};
