import React from 'react';

import uniqueId from 'lodash/uniqueId';

import { useUploadFile } from '@lumapps/lumx-files/hooks/useUploadFile';
import { useNotification } from '@lumapps/notifications/hooks/useNotifications';
import { UPLOADER_ACCEPT_MIME_TYPES } from '@lumapps/play/constants';
import { PLAY } from '@lumapps/play/keys';
import { UploadType } from '@lumapps/play/types';
import { fileMimeTypeIsValid } from '@lumapps/play/utils';

export interface UseUploadVideoParams {
    /** Upload input id (handle multiple inputs in the page) */
    inputId?: string;
    /**
     * Method triggered after user choose a file
     * ⚠️ This function should be memoized to avoid abusive rerender!
     */
    onUpload(e: File): void;
}

const INPUT_ID = 'upload-video-file-field';

export const useUploadVideo = ({ inputId = INPUT_ID, onUpload }: UseUploadVideoParams) => {
    const [uniqueInputId] = React.useState(() => uniqueId(inputId));
    const { error: showErrorNotification } = useNotification();

    const onFileUpload = React.useCallback(
        (file: File) => {
            if (!fileMimeTypeIsValid(file, UploadType.video)) {
                showErrorNotification({ translate: PLAY.WRONG_FORMAT_FILE });
                return;
            }
            onUpload(file);
        },
        [onUpload, showErrorNotification],
    );

    const { hiddenInput, openPicker } = useUploadFile({
        filePickerOptions: {
            accept: UPLOADER_ACCEPT_MIME_TYPES,
            multiple: false,
        },
        inputId: uniqueInputId,
        onSelect: (selectedFiles) => {
            if (selectedFiles.length > 0) {
                onFileUpload(selectedFiles[0].file);
            }
        },
    });

    return React.useMemo(
        () => ({
            /** Hidden input used to upload the video */
            hiddenInput,
            /** Callback called when a file is being uploaded */
            onFileUpload,
            /** Method used to open the computed picker */
            openPicker,
        }),
        [hiddenInput, onFileUpload, openPicker],
    );
};
