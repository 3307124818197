import React from 'react';

import { useDataAttributes } from '@lumapps/data-attributes';
import { Link as LumxLink } from '@lumapps/lumx/react';
import { useTranslate } from '@lumapps/translations';

import { CONTENT_EDITOR_TOPBAR_SCOPE } from '../../constants';
import { DESIGNER } from '../../keys';

export const ShowRequestRevisionDetailsLink = ({ onClick }: { onClick: () => void }) => {
    const { translateKey } = useTranslate();
    const { get } = useDataAttributes(CONTENT_EDITOR_TOPBAR_SCOPE);

    return (
        <LumxLink
            onClick={onClick}
            typography="body1"
            color="red"
            colorVariant="N"
            {...get({ element: 'request-revision-reason', action: 'open-details' })}
        >
            {translateKey(DESIGNER.REVISION_REQUESTED)}
        </LumxLink>
    );
};
