import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import { ColorPalette, Flag, type FlagProps } from '@lumapps/lumx/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';

import type { ContentPublicationStatus } from '../../constants';

const CLASSNAME = 'content-status-flag';

const PublicationStatusMap: Partial<Record<ContentPublicationStatus, { color: ColorPalette; label: string }>> = {
    draft: { color: ColorPalette.yellow, label: GLOBAL.DRAFT },
    published: { color: ColorPalette.green, label: GLOBAL.PUBLISHED },
    scheduled: { color: ColorPalette.green, label: GLOBAL.SCHEDULED },
    expired: { color: ColorPalette.red, label: GLOBAL.EXPIRED },
    archived: { color: ColorPalette.dark, label: GLOBAL.ARCHIVED },
};

export interface ContentStatusFlagProps extends Omit<FlagProps, 'color' | 'label'> {
    status: ContentPublicationStatus;
}

/**
 * Display the content status as a Flag.
 * @param ContentStatusFlagProps
 * @returns ContentStatusFlag
 */
export const ContentStatusFlag: React.FC<ContentStatusFlagProps> = ({ status, className, ...forwardedProps }) => {
    const { translateKey } = useTranslate();
    const statusProps = PublicationStatusMap[status];
    const { block } = useClassnames(CLASSNAME);

    if (!statusProps) {
        return null;
    }

    return (
        <Flag
            {...forwardedProps}
            className={block([className])}
            color={statusProps.color}
            label={translateKey(statusProps.label)}
        />
    );
};
