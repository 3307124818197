(function IIFE() {
    'use strict';

    /////////////////////////////

    function WidgetMetadataController($element, $scope, $timeout, Content, Features, Metadata, Translation, Utils, 
        Widget) {
        'ngInject';

        var vm = this;

        /////////////////////////////
        //                         //
        //    Private attributes   //
        //                         //
        /////////////////////////////

        /**
         * A DOM element that darkens the rest of the page when editing the content.
         *
         * @type {Element}
         */
        var _appFilter;

        /**
         * List of available metadatas.
         *
         * @type {Array}
         */
        vm.fullMetadatas = [];

        /////////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        /////////////////////////////

        vm.editingContent = false;

        /////////////////////////////

        /**
         * Services and utilities.
         */
        vm.Content = Content;
        vm.Metadata = Metadata;
        vm.Translation = Translation;
        vm.Utils = Utils;

        /////////////////////////////
        //                         //
        //    Private functions    //
        //                         //
        /////////////////////////////

        /**
         * Read the content.
         */
        function _readContent() {
            vm.editingContent = false;

            _appFilter.removeClass('app-filter--is-shown');

            $timeout(function delayFilterRemove() {
                $element.parents('.component-cell').removeClass('component-cell--hover-disabled');

                _appFilter.remove();

                Widget.isDragDisabled(false);
            }, 600);
        }

        /**
         * Edit the content.
         */
        function _editContent() {
            $element.parents('.component-cell').addClass('component-cell--hover-disabled');

            Widget.isDragDisabled(true);

            _appFilter = angular.element('<div/>', {
                'class': 'app-filter',
            });

            if (!Content.isCurrentDesignerMode('NEW_SIMPLE_WRITER_MODE')) {
                _appFilter.appendTo('.app-content').bind('click', function onFilterClick(evt) {
                    evt.stopPropagation();

                    $scope.$apply(function applyReadContent() {
                        _readContent();
                    });
                });
            }

            $timeout(function delayFilterDisplay() {
                vm.editingContent = true;

                _appFilter.addClass('app-filter--is-shown');
            });
        }

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        function buildUserMetadata() {
            angular.forEach(vm.metadatas, function forEachMetadata(metadata) {
                vm.fullMetadatas.push(Metadata.getMetadataFromKey(metadata, false, true));
            });
        }

        /**
         * Edit content in basic mode.
         */
        function editContentBasic() {
            if (Content.isCurrentDesignerMode('NEW_SIMPLE_WRITER_MODE')) {
                return;
            }
            
            if (Content.getAction() !== 'get' && Content.getViewMode() === 'basic' && !vm.editingContent) {
                _editContent();
            }
        }

        /**
         * Edit content in default or simple mode.
         */
        function editContentDefaultOrSimple() {
            var viewMode = Content.getViewMode();

            if (Content.getAction() !== 'get' && !vm.editingContent &&
                (viewMode === 'default' || viewMode === 'simple')) {
                _editContent();
            }
        }

        /**
         * Add metadata class according to metadata functionnal inner id.
         *
         * @param  {Object} metadataKey The metadata key.
         * @return {string} The metadata classes.
         */
        function getMetadataFunctionalInnerId(metadataKey) {
            var metadata = Metadata.getMetadataFromKey(metadataKey, false, true);
            var metadataClass;

            if (angular.isDefined(metadata) && angular.isDefinedAndFilled(metadata.functionalInnerId)) {
                metadataClass = 'widget-meta__metadata--' + metadata.functionalInnerId;
            }

            return metadataClass;
        }

        /**
         * Get widget classes.
         *
         * @return {Array} The widget class.
         */
        function getWidgetClass() {
            var widgetClass = [];

            vm.parentCtrl.getWidgetClass(widgetClass);

            widgetClass.push('widget-editable');

            if (vm.isWidgetEmpty()) {
                widgetClass.push('widget--is-empty');
            }

            if (vm.editingContent) {
                widgetClass.push('widget-metadata--is-editing');
                widgetClass.push('widget-editable--is-editing');
            }

            return widgetClass;
        }

        /**
         * Is widget empty in designer mode.
         *
         * @return {boolean} If the widget is empty or not.
         */
        function isWidgetEmpty() {
            return angular.isUndefinedOrEmpty(vm.metadatas);
        }

        /**
         * Is widget hidden in reading mode.
         *
         * @return {boolean} If the widget is hidden or not.
         */
        function isWidgetHidden() {
            if (!vm.parentCtrl.designerMode() && !vm.widget.properties.noResults &&
                angular.isUndefinedOrEmpty(vm.metadatas)) {
                vm.parentCtrl.isHidden = true;
            } else {
                vm.parentCtrl.isHidden = false;
            }

            return vm.parentCtrl.isHidden;
        }

        /////////////////////////////

        vm.editContentBasic = editContentBasic;
        vm.editContentDefaultOrSimple = editContentDefaultOrSimple;
        vm.getMetadataFunctionalInnerId = getMetadataFunctionalInnerId;
        vm.getWidgetClass = getWidgetClass;
        vm.isWidgetEmpty = isWidgetEmpty;
        vm.isWidgetHidden = isWidgetHidden;

        /////////////////////////////
        //                         //
        //          Events         //
        //                         //
        /////////////////////////////

        $scope.$on('contribution-mode', (evt, contributionMode) => {
            if (contributionMode === 'WRITER_MODE') {
                _editContent();
            } else {
                _readContent();
            }
        });

        /////////////////////////////

        /**
         * Initialize the controller.
         */
        function init() {
            vm.metadatas = [];

            var currentContent = Content.getCurrent();

            // Check if metadata still exist to prevent error.
            if (angular.isDefinedAndFilled(vm.widget.properties.metadata)) {
                Metadata.getRefactoredMetadata(false).then(function onGetRefactoredMetadataSuccess() {
                    angular.forEach(vm.widget.properties.metadata, function forEachMetadata(metadata) {
                        if (angular.isDefined(Metadata.getMetadataFromKey(metadata, false, true).key)) {
                            vm.metadatas.push(metadata);
                        }
                    });

                    vm.filteredMetadata = _.pick(currentContent.metadata, vm.metadatas);

                    buildUserMetadata();
                });
            } else {
                vm.filteredMetadata = _.pick(currentContent.metadata, vm.metadatas);

                buildUserMetadata();
            }

            if (Content.isCurrentDesignerMode('NEW_SIMPLE_WRITER_MODE')) {
                _editContent();
            }
        }

        init();

        /**
         * Set the parent controller of this directive.
         *
         * @param {Object} parentCtrl The parent controller.
         */
        this.setParentController = function setParentController(parentCtrl) {
            vm.parentCtrl = parentCtrl;
        };
    }

    /////////////////////////////

    function WidgetMetadataDirective() {
        'ngInject';

        function link(scope, el, attrs, ctrls) {
            ctrls[0].setParentController(ctrls[1]);
        }

        return {
            bindToController: true,
            controller: WidgetMetadataController,
            controllerAs: 'vm',
            link: link,
            replace: true,
            require: ['widgetMetadata', '^widget'],
            restrict: 'E',
            scope: {
                widget: '<',
            },
            // eslint-disable-next-line max-len
            templateUrl: '/client/front-office/modules/content/modules/widget/modules/widget-metadata/views/widget-metadata.html',
        };
    }

    /////////////////////////////

    angular.module('Widgets').directive('widgetMetadata', WidgetMetadataDirective);
})();
