import type { Content } from '@lumapps/contents/types';
import type { GetSelectedDateTimeZone } from '@lumapps/pickers/components/DateTimeZonePickerField/utils';
import type { ObjectValues } from '@lumapps/utils/types/ObjectValues';

import { CONTENT_DATE_FIELD_NAMES, CONTENT_PUBLICATION_SOURCE_TYPE, CONTENT_SETTINGS_FIELD_NAMES } from './constants';

export interface ScheduleFormValues {
    [CONTENT_DATE_FIELD_NAMES.SCHEDULED]: {
        timestamp: string;
        data?: GetSelectedDateTimeZone;
    };
    [CONTENT_DATE_FIELD_NAMES.EXPIRED]?: {
        timestamp: string;
        data?: GetSelectedDateTimeZone;
    };
}

export type ContentPublicationSourceType = ObjectValues<typeof CONTENT_PUBLICATION_SOURCE_TYPE>;

/** Pick the properties types of a content that are used in the content settings */
export type ContentPropertiesInSettingsType = Pick<
    Content,
    | 'title'
    | 'startDate'
    | 'endDate'
    | 'writerDetails'
    | 'metadata'
    | 'feedKeys'
    | 'featuredFeedKeys'
    | 'notifyFeedKeys'
    | 'editors'
    | 'customContentTypeTags'
>;

/** Associate the content properties to their field name in the setting */
export type ContentPropertyToContentSettingFieldsType = {
    [K in keyof ContentPropertiesInSettingsType]: ObjectValues<typeof CONTENT_SETTINGS_FIELD_NAMES>;
};

export enum LegacyContentSettingsDialogVariant {
    review = 'review',
    settings = 'settings',
}
