(function IIFE() {
    // ///////////////////////////

    function ContentService($state, AbstractContent, ContentFactory, ReduxStore) {
        'ngInject';

        const service = AbstractContent.createAbstractContentService(ContentFactory);

        // ///////////////////////////
        //                         //
        //          Redux          //
        //                         //
        // ///////////////////////////

        /**
         * Should retrun the service data that need to be synced with redux.
         *
         * @return The data. Aka. store shape.
         */
        function mapStateToRedux() {
            const currentContent = service.getCurrent();
            const action = service.getAction();
            const { params } = $state;
            const pickKeys = [
                'id',
                'mediaThumbnail',
                'customContentType',
                'slug',
                'status',
                'template',
                'title',
                'excerpt',
                'adminKeys',
                'author',
                'authorId',
                'editors',
                'type',
                'properties',
                'canEdit',
                'headerDetails',
                'isHomepage',
                'postTypes',
                'privacy',
                'securedRepository',
                'canContribute',
                'hasDriveFolder',
                'metadata',
                'lastRevision',
                'driveFolder',
                'lastPublishedAt',
                'communityFeedKey',
                'communityType',
                'privacy',
                'hasArticlesEnabled',
                'hasEventsEnabled',
                'renderingType',
                'externalKey',
                'authorDetails',
                'publicationDate',
                'comments',
                'liked',
                'likes',
                'customContentTypeTags',
                'customContentTypeDetails',
                'metadataDetails',
                'writerDetails',
                'endDate',
                'startDate',
                'hasCommentWidget',
                'updatedAt',
                'updatedByDetails'
            ];

            // Ensure everything is reset correctly when the current content is set.
            const defaults = pickKeys.reduce((acc, key) => ({
                ...acc,
                [key]: undefined,
            }), {});

            const pickedContent =_.pick(currentContent, pickKeys);

            return {
                id: '',
                entities: {},
                ...defaults,
                ...pickedContent,
                currentContributionMode: service.getCurrentContributionMode(),
                isDesignerMode: angular.isDefinedAndFilled(action) && action !== 'get',
                view: params ? params.view : null,
            };
        }

        function mapReduxToAngular(state) {
            /* Allow to reset the current content by trigger a redux update with an empty content.
             * This is usefull to notify Angular when that we are not a on a normal content page when accessing
             * Social advocacy dashboard. But we want to avoid to lose the current content so we check the $state.
             */

            const currentStateName = $state.current.name;

            if (!currentStateName.startsWith('app.front.content') && Object.keys(state).length === 0) {
                service.setCurrent(state);
            }
        }

        // The namespace for this service in the redux store.
        service.reduxReducerName = 'content';

        // The action type triggered when Angular updated the state.
        service.reduxUpdateActionType = 'content/update';

        // Expose the appropriate functions.
        service.mapStateToRedux = mapStateToRedux;
        service.mapReduxToAngular = mapReduxToAngular;

        // ///////////////////////////

        /**
         * Initialize the controller.
         */
        service.initService = function initService() {
            // Enable Redux sync.
            ReduxStore.subscribe(service, true);

            service.init();
        };

        // ///////////////////////////

        return service;
    }

    // ///////////////////////////

    angular.module('Services').service('Content', ContentService);
})();
