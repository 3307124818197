import React from 'react';

import { DetailedDirectoryEntryBlock } from '@lumapps/directories-entries/components/DetailedDirectoryEntryBlock/DetailedDirectoryEntryBlock';
import { useDispatch } from '@lumapps/redux/react';

import { toggleFavorite } from '../../ducks';
import { useDeletedUserDirectoryEntry } from '../../hooks/useDeleteUserDirectoryEntry';
import { useSaveUserDirectoryEntry } from '../../hooks/useSaveUserDirectoryEntry';
import type { BlockDirectoryEntry as BlockDirectoryEntryProps } from '../../types';

/**
 * BlockDirectoryEntry render
 */
export const BlockDirectoryEntry = (props: BlockDirectoryEntryProps) => {
    const { directoryEntryId, directoryId, widget, path } = props;
    const { openSaveDialog, creationDialogProps } = useSaveUserDirectoryEntry(widget, directoryId);
    const { deleteDialogProps, openDeleteDialog } = useDeletedUserDirectoryEntry(widget, directoryEntryId);

    // Toggle favorite actions
    const dispatch = useDispatch();
    const { favorite, unfavorite } = React.useMemo(() => {
        if (!path) {
            return {};
        }
        const [widgetId, ...blockPath] = path as string[];
        return {
            favorite: () => dispatch(toggleFavorite(false, directoryEntryId, widgetId, blockPath)),
            unfavorite: () => dispatch(toggleFavorite(true, directoryEntryId, widgetId, blockPath)),
        };
    }, [directoryEntryId, dispatch, path]);

    return (
        <DetailedDirectoryEntryBlock
            openDeleteDialog={openDeleteDialog}
            creationDialogProps={creationDialogProps}
            deleteDialogProps={deleteDialogProps}
            openSaveDialog={openSaveDialog}
            favorite={favorite}
            unfavorite={unfavorite}
            {...props}
        />
    );
};
