import camelCase from 'lodash/camelCase';
import filter from 'lodash/filter';
import get from 'lodash/get';
import includes from 'lodash/includes';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';
import without from 'lodash/without';

/////////////////////////////

function FeaturesService(
    $injector,
    Config,
    Customer,
    InitialSettings,
    Instance,
    ReduxStore,
    Widget,
    WidgetConstant,
) {
    'ngInject';

    const service = this;

    /////////////////////////////
    //                         //
    //    Private attributes   //
    //                         //
    /////////////////////////////

    /**
     * Wheter the current intance has a parent main nav inheritance.
     *
     * @type {Boolean}
     */
    let _hasMainNavInheritance;

    /////////////////////////////
    //                         //
    //    Private functions    //
    //                         //
    /////////////////////////////

    /**
     * Add extra account types based on what features are enabled or not for the Customer.
     */
    function _addAccountTypes() {
        const hasMicrosoft = service.hasFeature('microsoft');
        const hasOkta = service.hasFeature('okta');

        if (!hasMicrosoft && !hasOkta) {
            return;
        }

        if (hasMicrosoft) {
            Config.ACCOUNT_TYPES.push({
                name: undefined,
                value: 'microsoft',
            });
        }

        if (hasOkta) {
            Config.ACCOUNT_TYPES.push({
                name: undefined,
                value: 'okta',
            });
        }
    }

    /**
     * Add extra widgets to the AVAILABLE_WIDGETS array based on what features are enabled or not for the Customer.
     */
    function _addWidgets() {
        const ConfigInstance = $injector.get('ConfigInstance');

        let resetWidgets = false;

        ConfigInstance.AVAILABLE_WIDGETS = ConfigInstance.AVAILABLE_WIDGETS || [];
        const availableWidgetTypes = map(ConfigInstance.AVAILABLE_WIDGETS, 'type');

        angular.forEach(WidgetConstant, function forEachWidgetSettings(widgetSettings) {

            widgetSettings.relatedFeatureNames = widgetSettings.relatedFeatureNames || [];

            if (!widgetSettings.relatedFeatureRelation || widgetSettings.relatedFeatureRelation !== "OR") {
                const hasEveryFeatures = widgetSettings.relatedFeatureNames.every(function everyFeatureName(featureName) {
                    return service.hasFeature(featureName);
                });


                if (!hasEveryFeatures || includes(availableWidgetTypes, widgetSettings.options.type)) {
                    return;
                }
            }
            if (widgetSettings.relatedFeatureRelation === "OR") {
                const hasAtLeastOneFeature = widgetSettings.relatedFeatureNames.map(function atleastOneFeatureName(featureName) {
                    return service.hasFeature(featureName);
                }).filter(Boolean);

                if (!hasAtLeastOneFeature.length || includes(availableWidgetTypes, widgetSettings.options.type)) {
                    return;
                }
            }



            ConfigInstance.AVAILABLE_WIDGETS.push(Widget.create(widgetSettings.options));
            resetWidgets = true;
        });

        if (resetWidgets) {
            ConfigInstance.AVAILABLE_WIDGETS = sortBy(ConfigInstance.AVAILABLE_WIDGETS, 'type');
            Widget.resetAvailableWidgets();
        }
    }

    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    // Todo [Arnaud]: if it needs to be called before saving a customer, can we not apply it on preSave or similar?
    /**
     * Apply features statuses to be compliant with a customer model.
     * This method should be called before saving a customer.
     *
     * @param {Object} customer The customer to apply new featuresStatuses to.
     */
    function applyFeaturesStatus(customer) {
        if (!angular.isArray(customer.features)) {
            customer.features = [];
        }
        if (!angular.isArray(customer.excludedFeatures)) {
            customer.excludedFeatures = [];
        }

        angular.forEach(customer.featuresStatus, function forEachFeaturesStatus(value, key) {
            const isInFeatures = includes(customer.features, key);
            const isInExcludedFeatures = includes(customer.excludedFeatures, key);

            switch (value) {
                case 'disabled':
                    if (!isInExcludedFeatures) {
                        customer.excludedFeatures.push(key);
                    }
                    if (isInFeatures) {
                        customer.features = without(customer.features, key);
                    }

                    break;

                case 'enabled':
                    if (!isInFeatures) {
                        customer.features.push(key);
                    }
                    if (isInExcludedFeatures) {
                        customer.excludedFeatures = without(customer.excludedFeatures, key);
                    }

                    break;

                default:
                    if (isInFeatures) {
                        customer.features = without(customer.features, key);
                    }
                    if (isInExcludedFeatures) {
                        customer.excludedFeatures = without(customer.excludedFeatures, key);
                    }

                    break;
            }
        });
    }

    /**
     * List all features available.
     *
     * @return {Object} An object map of all available features. Key is the feature name. Value is the full feature.
     */
    function getAll() {
        return InitialSettings.FEATURES || {};
    }

    /**
     * Initialize features statuses for a customer.
     * This can be used with features forms.
     *
     * @param {Object} customer The customer to initialize the featuresStatuses on.
     */
    function initFeaturesStatus(customer) {
        customer.featuresStatus = {};
        const features = service.getAll();

        angular.forEach(features, function forEachFeatures(value, key) {
            let status = 'default';

            if (angular.isArray(customer.features) && includes(customer.features, key)) {
                status = 'enabled';
            } else if (angular.isArray(customer.excludedFeatures) && includes(customer.excludedFeatures, key)) {
                status = 'disabled';
            }

            customer.featuresStatus[key] = status;
        });
    }

    /**
     * List all features available to a given customer.
     * Feature must be enabled for all customers, or for trusted tester (and user is trusted tester).
     *
     * @param  {Object} customer The customer to retrieve the available features of.
     * @return {Array}  A list of feature objects.
     */
    function getAvailableForCustomer(customer) {
        // eslint-disable-next-line you-dont-need-lodash-underscore/filter
        return filter(service.getAll(), function filterAllFeatures(feature) {
            return feature.enabledCustomer || (feature.enabledTrustedCustomer && customer.trustedTester);
        });
    }

    /**
     * Indicates if the customer has almost one of the feature from a list.
     *
     * @param  {Array}   features An array of the feature to check.
     * @return {boolean} Whether or not the customer has one or more features enabled.
     */
    function hasOneOfFeatures(features) {
        if (angular.isUndefinedOrEmpty(features)) {
            return false;
        }

        return features.some((feature) => service.hasFeature(feature));
    }

    /**
     * Indicates if the current user has all the features from a set enabled or not.
     *
     * @param  {Object}  settings A settings object requiring one or more features.
     *                            Can be a notificationTypeSettings or a search tab object for example.
     * @return {boolean} Whether or not the current user has all the given features enabled.
     */
    function hasAllRequiredFeatures(settings) {
        if (angular.isUndefinedOrEmpty(settings.requiredFeatures)) {
            return true;
        }

        return settings.requiredFeatures.every((feature, _, __) => service.hasFeature(feature));
    }

    /**
     * Check if a feature is enabled for a given customer.
     *
     * @param  {string}  featureName The name of the feature to check on the current customer.
     * @param  {Object}  [customer]  The customer for whom to check if the feature is enabled or not.
     * @return {boolean} Whether the feature is enabled for the current customer or not.
     */
    function hasFeature(featureName, customer) {
        customer = customer || Customer.getCustomer();
        return includes(customer.enabledFeatures, featureName);
    }

    /**
     * Wheter or not the header displays the inherited parent main nav.
     *
     * @return {boolean} If the parent main nav is displayed.
     */
    function hasMainNavInheritance() {
        if (angular.isUndefined(_hasMainNavInheritance)) {
            _hasMainNavInheritance =
                service.hasFeature('main-nav-inheritance') &&
                angular.isDefinedAndFilled(get(Instance.getInstance(), 'parent'));
        }

        return _hasMainNavInheritance;
    }

    /**
     * Should return the service data that need to be synced with redux.
     *
     * @return {Object } The state aka. the store shape.
     */
    function mapStateToRedux() {
        const entities = getAll();

        return {
            entities,
        };
    }

    // The namespace for this service in the redux store.
    service.reduxReducerName = 'feature';

    // The action type triggered when Angular updated the state.
    service.reduxUpdateActionType = `${service.reduxReducerName}/update`;

    /////////////////////////////

    service.applyFeaturesStatus = applyFeaturesStatus;
    service.getAll = getAll;
    service.getAvailableForCustomer = getAvailableForCustomer;
    service.hasAllRequiredFeatures = hasAllRequiredFeatures;
    service.hasOneOfFeatures = hasOneOfFeatures;
    service.hasFeature = hasFeature;
    service.hasMainNavInheritance = hasMainNavInheritance;
    service.initFeaturesStatus = initFeaturesStatus;
    service.mapStateToRedux = mapStateToRedux;

    /////////////////////////////

    /**
     * Initialize the service.
     */
    service.init = function init() {
        // Enable Redux sync.
        ReduxStore.subscribe(service);

        _addWidgets();
        _addAccountTypes();
    };
}

/////////////////////////////

angular.module('Services').service('Features', FeaturesService);

/////////////////////////////

export { FeaturesService };
