export enum DESIGNER {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=609905836
     */
    SET_EXPIRATION_DATE = 'DESIGNER.SET_EXPIRATION_DATE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=610487110
     */
    EXPIRATION_DATE_BEFORE_SCHEDULE_ERROR = 'DESIGNER.EXPIRATION_DATE_BEFORE_SCHEDULE_ERROR',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=616555556
     */
    PUBLICATION_DATE_HELPER = 'DESIGNER.PUBLICATION_DATE_HELPER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=616555612
     */
    EXPIRATION_DATE_HELPER = 'DESIGNER.EXPIRATION_DATE_HELPER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=544858362
     */
    EXPIRATION_DATE_DATE_TOO_OLD_ERROR = 'CONTENT_ADMIN.EXPIRATION_DATE_DATE_TOO_OLD_ERROR',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=617489590
     */
    RECAP_PUBLISH_MESSAGE = 'DESIGNER.RECAP_PUBLISH_MESSAGE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=617489627
     */
    RECAP_SCHEDULE_MESSAGE = 'DESIGNER.RECAP_SCHEDULE_MESSAGE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=617490405
     */
    RECAP_SCHEDULE_TITLE = 'DESIGNER.RECAP_SCHEDULE_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=617490437
     */
    RECAP_PUBLISH_TITLE = 'DESIGNER.RECAP_PUBLISH_TITLE',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452734
     */
    MANDATORY_END_DATE_HELPER = 'CONTENT_SETTINGS_MANDATORY_END_DATE_HELP',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=247838918
     */
    DESIGNER_MODE_DESCRIPTION = 'SIMPLE_DESIGNER_MODE.EXPERT_MODE_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=563078105
     */
    WRITER_MODE_LABEL = 'SIMPLE_DESIGNER_MODE.WRITER_MODE_BUTTON_LABEL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=247800777
     */
    WRITER_MODE_DESCRIPTION = 'SIMPLE_DESIGNER_MODE.WRITER_MODE_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=570003987
     */
    READER_MODE_LABEL = 'SIMPLE_DESIGNER_MODE.READER_MODE_BUTTON_LABEL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=608550806
     */
    SHOW_VERSION_HISTORY_LABEL = 'DESIGNER.SHOW_VERSION_HISTORY_LABEL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=261017044
     */
    ADD_TO_NAVIGATION = 'NAVIGATION.ADD_TO_NAVIGATION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=261018696
     */
    ADD_TO_NAVIGATION_TOOLTIP = 'NAVIGATION.ADD_PUBLISHED_CONTENT',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452692
     */
    SET_AS_TEMPLATE = 'CONTENT_SET_TO_TEMPLATE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=612083656
     */
    UNPUBLISHED_SAVED_CHANGES_WARNING_MESSAGE = 'DESIGNER.UNPUBLISHED_SAVED_CHANGES_WARNING_MESSAGE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=612084251
     */
    SUBMITTED_FOR_REVIEW_MESSAGE = 'DESIGNER.SUBMITTED_FOR_REVIEW_MESSAGE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=612088002
     */
    REVISION_REQUESTED = 'DESIGNER.REVISION_REQUESTED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=612088654
     */
    SUBMIT_FOR_REVIEW_LABEL = 'DESIGNER.SUBMIT_FOR_REVIEW_LABEL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=95927119
     */
    PUBLISH_CHANGES_LABEL = 'FRONT.ARTICLES.DIALOG.PUBLISH_CHANGES',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13452686
     */
    UNPUBLISH = 'CONTENT_SET_TO_DRAFT',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=530168663
     */
    UNSCHEDULE = 'PLAY.VIDEO_STATUS_UNSCHEDULE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=612096589
     */
    PUBLISH_NOW_LABEL = 'DESIGNER.PUBLISH_NOW_LABEL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=612096637
     */
    EDIT_SCHEDULING = 'DESIGNER.EDIT_SCHEDULING',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=612294617
     */
    REFUSED_HELPER_MESSAGE = 'DESIGNER.REFUSED_HELPER_MESSAGE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=612520302
     */
    REFUSE_CONTENT_PUBLICATION_LABEL = 'DESIGNER.REFUSE_CONTENT_PUBLICATION_LABEL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=615107050
     */
    CONTENT_STATUS_ARCHIVED_ON_DATE = 'DESIGNER.CONTENT_STATUS_ARCHIVED_ON_DATE',
}
