import React from 'react';

import isArray from 'lodash/isArray';

import { classnames, color, padding, useClassnames } from '@lumapps/classnames';
import { DIRECTORIES_TAGS } from '@lumapps/directories-tags/keys';
import { DirectoryTag } from '@lumapps/directories-tags/types';
import { useDirectory } from '@lumapps/directories/hooks/useDirectory';
import { GroupChipPickerFieldProps } from '@lumapps/group-pickers/components/GroupChipPickerField';
import { getGroupAfter } from '@lumapps/group-pickers/utils';
import { Group } from '@lumapps/groups/types';
import { getCurrentInstance } from '@lumapps/instance/ducks/selectors';
import { currentLanguageSelector, getLanguages } from '@lumapps/languages/ducks/selectors';
import { GenericEntityBlock } from '@lumapps/lumx-blocks/components/GenericEntityBlock';
import { FieldValues } from '@lumapps/lumx-forms';
import { FormCheckboxField } from '@lumapps/lumx-forms/components/FormCheckboxField';
import { FormGroupPickerField } from '@lumapps/lumx-forms/components/FormGroupPickerField';
import { FormMetadataFieldset } from '@lumapps/lumx-forms/components/FormMetadataFieldset';
import { FormSelectTextField } from '@lumapps/lumx-forms/components/FormSelectTextField';
import { FormSubSection } from '@lumapps/lumx-forms/components/FormSubSection';
import { FormTextField } from '@lumapps/lumx-forms/components/FormTextField';
import { FormURLTextField } from '@lumapps/lumx-forms/components/FormURLTextField';
import { MultiLanguageFormDialog } from '@lumapps/lumx-forms/components/MultiLanguageFormDialog';
import { lumappsMicroApp } from '@lumapps/lumx/custom-icons';
import { mdiRefresh } from '@lumapps/lumx/icons';
import {
    Alignment,
    Emphasis,
    FlexBox,
    Heading,
    IconButton,
    Orientation,
    Placement,
    Size,
    Typography,
} from '@lumapps/lumx/react';
import {
    UseMetadataListWithSelected,
    useMetadataListWithSelected,
} from '@lumapps/metadata-pickers/hooks/useMetadataListWithSelected';
import { listInstanceMetadata } from '@lumapps/metadata/api/instanceMetadataApi';
import { Metadata } from '@lumapps/metadata/types';
import { microAppByIdFrontOffice } from '@lumapps/micro-apps/routes';
import { useSelector } from '@lumapps/redux/react';
import { createUrl } from '@lumapps/router';
import { GLOBAL, TranslateObject, useTranslate } from '@lumapps/translations';
import { isRelativeUrlValid } from '@lumapps/utils/string/isUrl';
import { BaseLoadingStatus } from '@lumapps/utils/types/BaseLoadingStatus';

import { ENTRY_TYPES, TEMPLATE_PREFIX } from '../../constants';
import { DIRECTORY_ENTRY } from '../../keys';
import { DirectoryEntry, DirectoryEntryCreationDialogProps } from '../../types';
import { getDirectoryEntryIcon, getDirectoryEntryImage } from '../../utils';
import { DirectoryEntriesTemplateFieldset } from '../DirectoryEntriesTemplateFieldset';
import { FormDirectoryEntryImage } from '../DirectoryEntryImage/FormDirectoryEntryImage';

import './index.scss';

const CLASSNAME = 'directory-entry-creation-dialog';
/**
 * Dialog for creating or editing a directory entry for a given parent directory.
 * @param DirectoryEntryCreationDialogProps
 * @returns DirectoryEntryCreationDialog
 */
export const DirectoryEntryCreationDialog: React.FC<DirectoryEntryCreationDialogProps> = ({
    className,
    entry,
    extension,
    onOpenPicker,
    onSubmit,
    lang,
    directoryTags = [],
    canSetMetadata,
    directoryId,
    isOpen,
    status,
    hideVisibility,
    ...props
}) => {
    const { translateKey, translateObject } = useTranslate();
    const { block, element } = useClassnames(CLASSNAME);
    const currentLanguage = useSelector(currentLanguageSelector);
    const languages = useSelector(getLanguages);
    const currentAccessedInstance = useSelector(getCurrentInstance);
    const [isDeletableFromFavoritesEnabled, setIsDeletableFromFavoritesEnabled] = React.useState(
        Boolean(entry?.isInFavoriteFeedKeys && entry?.isInFavoriteFeedKeys.length > 0),
    );
    const isNewEntry = !entry;

    const languageToUse = lang || currentLanguage;

    /**
     * Retrieve the metadata associated to the current site in order to display them as fields
     * in the dialog
     */
    const metadatas = useMetadataListWithSelected({
        getMetadata: listInstanceMetadata,
        params: {
            instance: currentAccessedInstance.id,
            withInheritance: true,
            rootOnly: false,
            // We need to fetch all metadatas (or at least as much as we can) in order to display them.
            maxResults: '999',
        },
        selectedMetadatas: entry?.metadata || [],
        fetchOnMount: isOpen && canSetMetadata,
        contentTypes: [],
    });

    /**
     * Retrieve the parent directory since there are a couple of infos that we need to make sure
     * they are up to date, specifically `directory.template`
     */
    const {
        directory: completeDirectory,
        status: directoryStatus,
        reset: directoryReset,
    } = useDirectory({
        params: { uid: directoryId },
        shouldFetch: isOpen,
    });

    const entryType = extension ? ENTRY_TYPES.MICROAPP : entry?.type || ENTRY_TYPES.LINK;

    const isLoading =
        status === BaseLoadingStatus.loading || metadatas.isLoading || directoryStatus === BaseLoadingStatus.loading;

    const onEntrySubmit = (entry: FieldValues) => {
        const {
            tags,
            feedKeys,
            isInFavoriteFeedKeys,
            metadatas: selectedMetadatas,
            thumbnail,
            type,
            ...restOfProps
        } = entry;

        const metadata: string[] = [];

        if (selectedMetadatas) {
            /**
             * Metadatas are sent over as a flat list of ids. We go through the metadata object and we
             * flat out the list. Selected values can be either arrays or simple items, we parse them differently
             * depending on their type.
             */
            Object.values(selectedMetadatas as UseMetadataListWithSelected['selectedMetadatas'])
                .filter(Boolean)
                .forEach((met: Metadata | Metadata[]) => {
                    if (isArray(met)) {
                        met.forEach((m) => {
                            metadata.push(m.id);
                        });
                    } else {
                        metadata.push(met.id);
                    }
                });
        }

        const templateValues: DirectoryEntry['values'] = {};

        /** We revert the changes we did in order to send them to the backend. */
        Object.keys(restOfProps).forEach((key) => {
            if (key.indexOf(TEMPLATE_PREFIX) >= 0) {
                templateValues[key.replace(TEMPLATE_PREFIX, '')] = restOfProps[key];
            }
        });

        const microApp = extension
            ? {
                  link: { en: createUrl(microAppByIdFrontOffice({ id: extension.id })) },
                  resourceId: extension.id,
                  thumbnail: extension.icon.en,
                  resourceProperties: {
                      componentSettings: [{ icon: extension.icon }],
                  },
                  type: ENTRY_TYPES.MICROAPP,
              }
            : {};

        onSubmit({
            ...restOfProps,
            type: type || ENTRY_TYPES.LINK,
            thumbnail: thumbnail ? thumbnail.id : undefined,
            ...microApp,
            directory: directoryId,
            tags: tags ? (tags as DirectoryTag[]).map((tag) => tag.uuid) : [],
            feedKeys: feedKeys ? (feedKeys as Group[]).map((group) => group.id) : [],
            isInFavoriteFeedKeys: isInFavoriteFeedKeys
                ? (isInFavoriteFeedKeys as Group[]).map((group) => group.id)
                : [],
            metadata,
            values: templateValues,
        });
    };

    const templateValues: TranslateObject | string | string[] = {};

    /**
     * From the values that come from the backend, we add a prefix in order to
     * differentiate these values from the other ones from the directory entry.
     */
    if (entry && entry.values) {
        Object.keys(entry.values).forEach((val) => {
            const values = entry.values ? entry.values[val] : {};

            templateValues[`${TEMPLATE_PREFIX}${val}`] = values;
        });
    }

    const customSearchParams = React.useMemo<GroupChipPickerFieldProps['customSearchParams']>(
        () => ({ excludeSegments: false }),
        [],
    );

    return (
        <MultiLanguageFormDialog
            className={block(undefined, [className])}
            {...props}
            status={isLoading ? BaseLoadingStatus.loading : BaseLoadingStatus.idle}
            isOpen={isOpen}
            dialogProps={{
                ...props.dialogProps,
                preventCloseOnClick: true,
                preventCloseOnEscape: true,
                onVisibilityChange: (isVisible) => {
                    if (props.dialogProps?.onVisibilityChange) {
                        props.dialogProps?.onVisibilityChange(isVisible);
                    }

                    if (!isVisible) {
                        metadatas.reset();
                        metadatas.resetSelectedMetadata();
                        directoryReset();
                    }
                },
            }}
            heading={
                <Heading as="h2" typography={Typography.title}>
                    {translateKey(isNewEntry ? DIRECTORY_ENTRY.NEW_ENTRY : DIRECTORY_ENTRY.EDIT_ENTRY)}
                </Heading>
            }
            form={{
                values:
                    !isLoading && entry
                        ? {
                              ...entry,
                              tags: entry ? directoryTags.filter((tag) => entry.tags?.includes(tag.uuid)) : [],
                              metadatas: metadatas ? metadatas.selectedMetadatas : {},
                              thumbnail: getDirectoryEntryImage(entry),
                              ...templateValues,
                          }
                        : undefined,
            }}
            defaultLanguage={languageToUse}
            languages={languages}
            scope={CLASSNAME}
            onSubmit={onEntrySubmit}
        >
            <FormSubSection
                title={translateKey(GLOBAL.GENERAL)}
                orientation={Orientation.vertical}
                contentWrapperProps={{ orientation: 'vertical', gap: 'huge' }}
            >
                {entryType === ENTRY_TYPES.LINK ? (
                    <FlexBox orientation={Orientation.horizontal} gap={Size.huge} hAlign={Alignment.top}>
                        <FormDirectoryEntryImage />

                        <FlexBox
                            orientation={Orientation.vertical}
                            gap={Size.huge}
                            className={element('general-fields')}
                        >
                            <FormTextField name="name" isMultiLanguage isRequired label={translateKey(GLOBAL.NAME)} />

                            <FormURLTextField
                                label={translateKey(GLOBAL.URL)}
                                name="link"
                                isMultiLanguage
                                urlValidator={isRelativeUrlValid}
                            />
                        </FlexBox>
                    </FlexBox>
                ) : (
                    <FlexBox orientation={Orientation.vertical} gap={Size.huge}>
                        <FormTextField
                            name="name"
                            controllerProps={{ defaultValue: extension ? extension.name : '' }}
                            isMultiLanguage
                            isRequired
                            label={translateKey(GLOBAL.NAME)}
                        />

                        <GenericEntityBlock
                            className={classnames(padding('all', 'big'), color('background', 'dark', 'L6'))}
                            customActions={
                                <IconButton
                                    emphasis={Emphasis.low}
                                    icon={mdiRefresh}
                                    label={translateKey(GLOBAL.REPLACE)}
                                    onClick={onOpenPicker}
                                />
                            }
                            shouldCenterTitle
                            title={translateObject(extension ? extension.name : entry?.name)}
                            thumbnail={{
                                icon: lumappsMicroApp,
                                image: translateObject(extension?.icon || getDirectoryEntryIcon(entry)) || '',
                            }}
                        />
                    </FlexBox>
                )}

                {directoryTags && directoryTags.length > 0 ? (
                    <FormSelectTextField<DirectoryTag>
                        selectionType="multiple"
                        name="tags"
                        options={directoryTags}
                        label={translateKey(DIRECTORIES_TAGS.DIRECTORY_TAGS)}
                        getOptionName={(tag) => translateObject(tag.name) as string}
                        getOptionId={(tag) => tag.uuid}
                        autoFilter
                    />
                ) : null}

                {completeDirectory ? <DirectoryEntriesTemplateFieldset directory={completeDirectory} /> : null}
            </FormSubSection>

            {canSetMetadata ? (
                <FormMetadataFieldset
                    metadatas={metadatas}
                    gap={Size.huge}
                    title={translateKey(GLOBAL.METADATA)}
                    showMultipleLevels
                    contentWrapperProps={{ orientation: 'vertical' }}
                />
            ) : null}

            {!isLoading && !hideVisibility ? (
                <FormSubSection
                    title={translateKey(GLOBAL.VISIBILITY)}
                    orientation={Orientation.vertical}
                    contentWrapperProps={{ orientation: 'vertical', gap: 'huge' }}
                    withSeparator={false}
                >
                    <FormGroupPickerField
                        name="feedKeys"
                        label={translateKey(GLOBAL.VISIBLE_BY)}
                        showHelper={false}
                        displayAllGroup
                        displayPublicGroup
                        shouldCloseOnSelect={false}
                        fitToAnchorWidth
                        customSearchParams={customSearchParams}
                        getGroupAfter={getGroupAfter}
                    />

                    <FlexBox orientation={Orientation.vertical}>
                        <FormGroupPickerField
                            name="isInFavoriteFeedKeys"
                            label={translateKey(DIRECTORY_ENTRY.IN_FAVORITE_FEEDS)}
                            showHelper={false}
                            displayAllGroup
                            displayPublicGroup
                            shouldCloseOnSelect={false}
                            placement={Placement.TOP}
                            fitToAnchorWidth
                            controllerProps={{
                                onValueChanged: (groups: Group[]) => {
                                    /**
                                     * The checkbox for [name="deletableFromFavorite"] should not be
                                     * enabled if there are no feeds to be included as favorites
                                     */
                                    if (groups) {
                                        setIsDeletableFromFavoritesEnabled(groups.length > 0);
                                    }
                                },
                            }}
                            customSearchParams={customSearchParams}
                            getGroupAfter={getGroupAfter}
                        />

                        {isDeletableFromFavoritesEnabled ? (
                            <FormCheckboxField
                                name="deletableFromFavorite"
                                label={translateKey(DIRECTORY_ENTRY.IS_DELETABLE_FROM_FAVORITE)}
                                isDisabled={!isDeletableFromFavoritesEnabled}
                                className={element('deletable-from-favorites')}
                                placement={Placement.TOP}
                                controllerProps={{
                                    dependsOn: {
                                        fields: ['isInFavoriteFeedKeys'],
                                        generator: (values: Record<string, string[]>, currentValue: boolean) => {
                                            /**
                                             * If there are values in `isInFavoriteFeedKeys`, this checkbox should
                                             * be disabled and the value should be set to `false`.
                                             */
                                            const isInFavoriteFeedKeys = values.isInFavoriteFeedKeys || [];

                                            return isInFavoriteFeedKeys.length === 0 ? false : currentValue;
                                        },
                                    },
                                }}
                            />
                        ) : null}
                    </FlexBox>
                </FormSubSection>
            ) : null}
        </MultiLanguageFormDialog>
    );
};
