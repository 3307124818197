import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import { ContentPublicationStatus } from '@lumapps/contents/constants';
import { type AuthorDetails } from '@lumapps/contents/types';
import { RelativeDateWithTranslation } from '@lumapps/lumx-texts/components/RelativeDateWithTranslation';
import { mdiAlert, mdiAlertCircle } from '@lumapps/lumx/icons';
import { FlexBox, Icon, Text } from '@lumapps/lumx/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';
import { formatFromNow } from '@lumapps/utils/date/formatFromNow';
import { useBooleanState } from '@lumapps/utils/hooks/useBooleanState';

import { TOPBAR_CLASSNAME } from '../../constants';
import { DESIGNER } from '../../keys';
import { RefusedReasonDialog } from './RefusedReasonDialog';
import { ShowRequestRevisionDetailsLink } from './ShowRequestRevisionDetailsLink';

interface ContentStatusMetadataProps {
    refusedReason?: string;
    publicationStatus: ContentPublicationStatus;
    expirationDate?: string;
    scheduledDate?: string;
    updatedAt?: string;
    updatedBy?: AuthorDetails;
    publicationDate?: string;
    hasPendingDraft: boolean;
    isRevisionRequested?: boolean;
    isAwaitingValidation?: boolean;
}

/**
 *
 * Display the content status metadata.
 *
 * @param ContentStatusMetadataProps
 * @returns ContentStatusMetadata
 */
export const ContentStatusMetadata = ({
    publicationStatus,
    expirationDate,
    scheduledDate,
    updatedAt,
    updatedBy,
    publicationDate,
    hasPendingDraft,
    isAwaitingValidation,
    isRevisionRequested,
    refusedReason,
}: ContentStatusMetadataProps) => {
    const { translateKey, translateAndReplace } = useTranslate();

    const { element } = useClassnames(TOPBAR_CLASSNAME);
    const [isRefusedReasonDialogOpen, , closeRefusedReasonDialog, openRefusedReasonDialog] = useBooleanState(false);
    let metadataMessage;

    if (publicationStatus === 'archived') {
        metadataMessage = (
            <Text as="span" truncate typography="body1" color="dark" colorVariant="L2">
                {translateAndReplace(DESIGNER.CONTENT_STATUS_ARCHIVED_ON_DATE, {
                    DATE: formatFromNow({
                        date: updatedAt,
                        thresholdDisplayFullDate: 0,
                        aboveThresholdDisplayFullDate: 0,
                        dateStringFormat: 'lll',
                    }),
                })}
            </Text>
        );
    } else if (publicationStatus === 'expired') {
        if (isRevisionRequested && updatedAt && updatedBy) {
            metadataMessage = (
                <>
                    <Icon size="xxs" icon={mdiAlert} colorVariant="N" color="red" />
                    <Text as="p" truncate>
                        <ShowRequestRevisionDetailsLink onClick={openRefusedReasonDialog} />
                        <Text as="span" typography="body1" color="dark" colorVariant="L2">
                            {' '}
                            -{' '}
                            {translateAndReplace(GLOBAL.EXPIRED_ON_DATE, {
                                DATE: formatFromNow({
                                    date: expirationDate,
                                    thresholdDisplayFullDate: 0,
                                    aboveThresholdDisplayFullDate: 0,
                                    dateStringFormat: 'lll',
                                }),
                            }).replace(':', '')}
                        </Text>
                    </Text>

                    <RefusedReasonDialog
                        isOpen={isRefusedReasonDialogOpen}
                        closeDialog={closeRefusedReasonDialog}
                        refusedReason={refusedReason}
                        updatedBy={updatedBy}
                        updatedAt={updatedAt}
                    />
                </>
            );
        } else if (isAwaitingValidation) {
            metadataMessage = (
                <>
                    <Icon size="xxs" icon={mdiAlertCircle} colorVariant="D2" color="yellow" />
                    <Text as="span" truncate typography="body1" color="dark" colorVariant="L2">
                        {translateKey(DESIGNER.SUBMITTED_FOR_REVIEW_MESSAGE)} -{' '}
                        {translateAndReplace(GLOBAL.EXPIRED_ON_DATE, {
                            DATE: formatFromNow({
                                date: expirationDate,
                                thresholdDisplayFullDate: 0,
                                aboveThresholdDisplayFullDate: 0,
                                dateStringFormat: 'lll',
                            }),
                        }).replace(':', '')}
                    </Text>
                </>
            );
        } else if (hasPendingDraft) {
            metadataMessage = (
                <>
                    <Icon size="xxs" icon={mdiAlertCircle} colorVariant="D2" color="yellow" />
                    <Text as="span" truncate typography="body1" color="dark" colorVariant="L2">
                        {translateKey(DESIGNER.UNPUBLISHED_SAVED_CHANGES_WARNING_MESSAGE)} -{' '}
                        {translateAndReplace(GLOBAL.EXPIRED_ON_DATE, {
                            DATE: formatFromNow({
                                date: expirationDate,
                                thresholdDisplayFullDate: 0,
                                aboveThresholdDisplayFullDate: 0,
                                dateStringFormat: 'lll',
                            }),
                        }).replace(':', '')}
                    </Text>
                </>
            );
        } else {
            metadataMessage = (
                <Text as="span" truncate typography="body1" color="dark" colorVariant="L2">
                    {translateAndReplace(GLOBAL.EXPIRED_ON_DATE, {
                        DATE: formatFromNow({
                            date: expirationDate,
                            thresholdDisplayFullDate: 0,
                            aboveThresholdDisplayFullDate: 0,
                            dateStringFormat: 'lll',
                        }),
                    }).replace(':', '')}
                </Text>
            );
        }
    } else if (publicationStatus === 'scheduled') {
        if (isRevisionRequested && updatedAt && updatedBy) {
            metadataMessage = (
                <>
                    <Icon size="xxs" icon={mdiAlert} colorVariant="N" color="red" />
                    <Text as="p" truncate>
                        <ShowRequestRevisionDetailsLink onClick={openRefusedReasonDialog} />

                        <Text as="span" typography="body1" color="dark" colorVariant="L2">
                            {' '}
                            -{' '}
                            {translateAndReplace(GLOBAL.SAVED_ON, {
                                DATE: formatFromNow({
                                    date: updatedAt,
                                    thresholdDisplayFullDate: 0,
                                    aboveThresholdDisplayFullDate: 0,
                                    dateStringFormat: 'lll',
                                }),
                            })}
                        </Text>
                    </Text>

                    <RefusedReasonDialog
                        isOpen={isRefusedReasonDialogOpen}
                        closeDialog={closeRefusedReasonDialog}
                        refusedReason={refusedReason}
                        updatedBy={updatedBy}
                        updatedAt={updatedAt}
                    />
                </>
            );
        } else if (isAwaitingValidation) {
            metadataMessage = (
                <>
                    <Icon size="xxs" icon={mdiAlertCircle} colorVariant="D2" color="yellow" />
                    <Text as="span" truncate typography="body1" color="dark" colorVariant="L2">
                        {translateKey(DESIGNER.SUBMITTED_FOR_REVIEW_MESSAGE)} -{' '}
                        {translateAndReplace(GLOBAL.SCHEDULED_ON_DATE, {
                            DATE: formatFromNow({
                                date: scheduledDate,
                                thresholdDisplayFullDate: 0,
                                aboveThresholdDisplayFullDate: 0,
                                dateStringFormat: 'lll',
                            }),
                        }).replace(':', '')}
                    </Text>
                </>
            );
        } else if (hasPendingDraft) {
            metadataMessage = (
                <>
                    <Icon size="xxs" icon={mdiAlertCircle} colorVariant="D2" color="yellow" />
                    <Text as="span" truncate typography="body1" color="dark" colorVariant="L2">
                        {translateKey(DESIGNER.UNPUBLISHED_SAVED_CHANGES_WARNING_MESSAGE)} -{' '}
                        {translateAndReplace(GLOBAL.SCHEDULED_ON_DATE, {
                            DATE: formatFromNow({
                                date: scheduledDate,
                                thresholdDisplayFullDate: 0,
                                aboveThresholdDisplayFullDate: 0,
                                dateStringFormat: 'lll',
                            }),
                        }).replace(':', '')}
                    </Text>
                </>
            );
        } else {
            metadataMessage = (
                <Text as="span" truncate typography="body1" color="dark" colorVariant="L2">
                    {translateAndReplace(GLOBAL.SCHEDULED_ON_DATE, {
                        DATE: formatFromNow({
                            date: scheduledDate,
                            thresholdDisplayFullDate: 0,
                            aboveThresholdDisplayFullDate: 0,
                            dateStringFormat: 'lll',
                        }),
                    }).replace(':', '')}
                </Text>
            );
        }
    } else if (publicationStatus === 'published' && publicationDate) {
        if (isRevisionRequested && updatedAt && updatedBy) {
            metadataMessage = (
                <>
                    <Icon size="xxs" icon={mdiAlert} colorVariant="N" color="red" />
                    <Text as="p" truncate>
                        <ShowRequestRevisionDetailsLink onClick={openRefusedReasonDialog} />

                        <Text as="span" typography="body1" color="dark" colorVariant="L2">
                            {' '}
                            -{' '}
                            <RelativeDateWithTranslation
                                as="span"
                                key="publicationDate"
                                date={publicationDate}
                                relativeDateProps={{ options: { fullDateFormat: 'lll' } }}
                                translations={{
                                    underThreshold: {
                                        translationKey: GLOBAL.RELATIVE_PUBLISHED_DATE,
                                        dateReplacementKey: 'RELATIVE_DATE',
                                    },
                                    overThreshold: {
                                        translationKey: GLOBAL.PUBLISHED_ON_DATE,
                                        dateReplacementKey: 'DATE',
                                    },
                                }}
                            />
                        </Text>
                    </Text>

                    <RefusedReasonDialog
                        isOpen={isRefusedReasonDialogOpen}
                        closeDialog={closeRefusedReasonDialog}
                        refusedReason={refusedReason}
                        updatedBy={updatedBy}
                        updatedAt={updatedAt}
                    />
                </>
            );
        } else if (isAwaitingValidation) {
            metadataMessage = (
                <>
                    <Icon size="xxs" icon={mdiAlertCircle} colorVariant="D2" color="yellow" />
                    <Text as="span" truncate typography="body1" color="dark" colorVariant="L2">
                        {translateKey(DESIGNER.SUBMITTED_FOR_REVIEW_MESSAGE)} -{' '}
                        <RelativeDateWithTranslation
                            as="span"
                            key="publicationDate"
                            relativeDateProps={{ options: { fullDateFormat: 'lll' } }}
                            date={publicationDate}
                            translations={{
                                underThreshold: {
                                    translationKey: GLOBAL.RELATIVE_PUBLISHED_DATE,
                                    dateReplacementKey: 'RELATIVE_DATE',
                                },
                                overThreshold: {
                                    translationKey: GLOBAL.PUBLISHED_ON_DATE,
                                    dateReplacementKey: 'DATE',
                                },
                            }}
                        />
                    </Text>
                </>
            );
        } else if (hasPendingDraft) {
            metadataMessage = (
                <>
                    <Icon size="xxs" icon={mdiAlertCircle} colorVariant="D2" color="yellow" />
                    <Text as="span" truncate typography="body1" color="dark" colorVariant="L2">
                        {translateKey(DESIGNER.UNPUBLISHED_SAVED_CHANGES_WARNING_MESSAGE)} -{' '}
                        <RelativeDateWithTranslation
                            as="span"
                            key="publicationDate"
                            relativeDateProps={{ options: { fullDateFormat: 'lll' } }}
                            date={publicationDate}
                            translations={{
                                underThreshold: {
                                    translationKey: GLOBAL.RELATIVE_PUBLISHED_DATE,
                                    dateReplacementKey: 'RELATIVE_DATE',
                                },
                                overThreshold: {
                                    translationKey: GLOBAL.PUBLISHED_ON_DATE,
                                    dateReplacementKey: 'DATE',
                                },
                            }}
                        />
                    </Text>
                </>
            );
        } else {
            metadataMessage = (
                <Text as="span" truncate typography="body1" color="dark" colorVariant="L2">
                    <RelativeDateWithTranslation
                        as="span"
                        key="publicationDate"
                        date={publicationDate}
                        relativeDateProps={{ options: { fullDateFormat: 'lll' } }}
                        translations={{
                            underThreshold: {
                                translationKey: GLOBAL.RELATIVE_PUBLISHED_DATE,
                                dateReplacementKey: 'RELATIVE_DATE',
                            },
                            overThreshold: {
                                translationKey: GLOBAL.PUBLISHED_ON_DATE,
                                dateReplacementKey: 'DATE',
                            },
                        }}
                    />
                </Text>
            );
        }
    } else if (publicationStatus === 'draft' && updatedAt) {
        if (isRevisionRequested && updatedAt && updatedBy) {
            metadataMessage = (
                <>
                    <Icon size="xxs" icon={mdiAlert} colorVariant="N" color="red" />
                    <Text as="p" truncate>
                        <ShowRequestRevisionDetailsLink onClick={openRefusedReasonDialog} />

                        <Text as="span" typography="body1" color="dark" colorVariant="L2">
                            {' '}
                            -{' '}
                            {translateAndReplace(GLOBAL.SAVED_ON, {
                                DATE: formatFromNow({
                                    date: updatedAt,
                                    thresholdDisplayFullDate: 0,
                                    aboveThresholdDisplayFullDate: 0,
                                    dateStringFormat: 'lll',
                                }),
                            })}
                        </Text>
                    </Text>

                    <RefusedReasonDialog
                        isOpen={isRefusedReasonDialogOpen}
                        closeDialog={closeRefusedReasonDialog}
                        refusedReason={refusedReason}
                        updatedBy={updatedBy}
                        updatedAt={updatedAt}
                    />
                </>
            );
        } else if (isAwaitingValidation) {
            metadataMessage = (
                <>
                    <Icon size="xxs" icon={mdiAlertCircle} colorVariant="D2" color="yellow" />
                    <Text as="span" truncate typography="body1" color="dark" colorVariant="L2">
                        {translateKey(DESIGNER.SUBMITTED_FOR_REVIEW_MESSAGE)} -{' '}
                        {translateAndReplace(GLOBAL.SAVED_ON, {
                            DATE: formatFromNow({
                                date: updatedAt,
                                thresholdDisplayFullDate: 0,
                                aboveThresholdDisplayFullDate: 0,
                                dateStringFormat: 'lll',
                            }),
                        })}
                    </Text>
                </>
            );
        } else {
            metadataMessage = (
                <Text as="span" truncate typography="body1" color="dark" colorVariant="L2">
                    {translateAndReplace(GLOBAL.SAVED_ON, {
                        DATE: formatFromNow({
                            date: updatedAt,
                            thresholdDisplayFullDate: 0,
                            aboveThresholdDisplayFullDate: 0,
                            dateStringFormat: 'lll',
                        }),
                    })}
                </Text>
            );
        }
    }

    return metadataMessage ? (
        <FlexBox
            id="status-metadata"
            className={element('status-metadata')}
            aria-live="polite"
            orientation="horizontal"
            gap="tiny"
            hAlign="center"
        >
            {metadataMessage}
        </FlexBox>
    ) : null;
};
