import set from 'lodash/set';

import type { AnyAction } from '@lumapps/redux';
import createSlice, { type PayloadAction } from '@lumapps/redux/createSlice';
import { mergeObjectOnly } from '@lumapps/utils/object/mergeObjectOnly';

import { DEFAULT_LANGUAGE } from '../constants';

const DOMAIN = 'locale';

const ANGULAR_INIT = `__angular__/${DOMAIN}/update`;

interface LocaleState {
    current: string;
    default: string;
    fallback: string;
    inputCustomerLanguage: string;
    inputLanguage: string;
    preferred: string;
}

const initialState = {
    current: '',
    default: '',
    fallback: '',
    inputCustomerLanguage: '',
    inputLanguage: '',
    preferred: '',
};

const { actions, reducer } = createSlice({
    domain: 'locale',
    initialState,
    reducers: {
        [ANGULAR_INIT]: (state: any, action: AnyAction) => {
            mergeObjectOnly(state, action.newState);
        },
        updateLocaleCurrent: (state: LocaleState, action: PayloadAction<{ lang: string }>) => {
            const { lang = DEFAULT_LANGUAGE } = action.payload;

            set(state, 'current', lang);
        },
        updateLocaleDefault: (state: LocaleState, action: PayloadAction<{ lang: string }>) => {
            const { lang = DEFAULT_LANGUAGE } = action.payload;

            set(state, 'default', lang);
        },
        updateLocaleFallback: (state: LocaleState, action: PayloadAction<{ lang: string }>) => {
            const { lang = DEFAULT_LANGUAGE } = action.payload;

            set(state, 'fallback', lang);
        },
        updateLocaleInputCustomerLanguage: (state: LocaleState, action: PayloadAction<{ lang: string }>) => {
            const { lang = DEFAULT_LANGUAGE } = action.payload;

            set(state, 'inputCustomerLanguage', lang);
        },
        updateLocaleInputLanguage: (state: LocaleState, action: PayloadAction<{ lang: string }>) => {
            const { lang = DEFAULT_LANGUAGE } = action.payload;

            set(state, 'inputLanguage', lang);
        },
        updateLocalePreferred: (state: LocaleState, action: PayloadAction<{ lang: string }>) => {
            const { lang = DEFAULT_LANGUAGE } = action.payload;

            set(state, 'preferred', lang);
        },
    },
});

export { actions, reducer };
