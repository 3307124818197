import React from 'react';

import moment from 'moment';

import { background, classnames, margin, padding, useClassnames } from '@lumapps/classnames';
import { useDataAttributes } from '@lumapps/data-attributes';
import { getLearningPathRoute } from '@lumapps/learning-learning-path/routes';
import { getTrainingCourseRoute, SUB_PAGES_VIEWS } from '@lumapps/learning-training-course/routes';
import { FILTER_TRAINING_TYPE } from '@lumapps/learning/constants';
import { isLearningTrainingPageReactEnabled } from '@lumapps/learning/ducks/selectors';
import { ProgressionBar } from '@lumapps/lumx-progression/components/ProgressionBar';
import { mdiAccountCog, mdiCalendarAlert, mdiCalendarClock, mdiTrophyAward } from '@lumapps/lumx/icons';
import { Button, Emphasis, Flag, FlexBox, ImageBlock, Link, Text, Theme, Tooltip } from '@lumapps/lumx/react';
import { MICROAPP } from '@lumapps/micro-apps-builder/keys';
import { useSelector } from '@lumapps/redux/react';
import { Link as RouterLink } from '@lumapps/router';
import { GLOBAL, useTranslate } from '@lumapps/translations';

import { PROGRESS_BAR_MAX_WIDTH, TRAINING_TYPE_CONFIG } from '../../constants';
import { TRAININGS } from '../../keys';
import { trainingView } from '../../routes';
import { TRAINING_TYPE, Training as TrainingType } from '../../types';

import './index.scss';

export interface TrainingInformationsProps {
    scope: string;
    training: TrainingType;
    isButtonDisplayed?: boolean;
    theme?: Theme;
}

const CLASSNAME = 'training-informations';

/**
 * This component is used as a Block in the personal feed
 * @param TrainingProps
 * @returns Training
 */
export const TrainingInformations: React.FC<TrainingInformationsProps> = ({
    training,
    isButtonDisplayed = true,
    theme = Theme.light,
    scope,
}) => {
    const { translateKey, translateAndReplace, pluralize } = useTranslate();
    const { element } = useClassnames(CLASSNAME);
    const { get } = useDataAttributes(scope);
    const isLearningTrainingPageReact = useSelector(isLearningTrainingPageReactEnabled);

    const title = training.title?.length ? training.title : translateKey(GLOBAL.UNTITLED);
    const isProgressBarDisplayed = training.progress > 0 && training.progress <= 100;
    const category = training.categoriesTitles?.[0]?.length
        ? training.categoriesTitles[0]
        : translateKey(TRAININGS.NO_CATEGORY);
    const backgroundTheme = theme === Theme.dark ? background('dark', 'N') : background('light', 'N');
    const colorTheme = theme === Theme.dark ? 'light' : 'dark';
    const trainingType = FILTER_TRAINING_TYPE[training.type];
    const trainingTypeRoute =
        trainingType === TRAINING_TYPE.training
            ? getTrainingCourseRoute(training.identifier, SUB_PAGES_VIEWS.ACTIVITIES)
            : getLearningPathRoute(training.identifier);

    const isRecommendedFlagDisplayed = training.isRecommended && !training.dueDate;
    const now = moment();
    const dueMoment = moment(training?.dueDate);
    const container = isProgressBarDisplayed ? 'container-with-progress-bar' : 'container-without-progress-bar';
    const differenceInDays = dueMoment.diff(now, 'days');
    let differenceInDaysAbsolue = differenceInDays;
    if (differenceInDaysAbsolue < 0) {
        differenceInDaysAbsolue *= -1;
    }
    return (
        <Link
            linkAs={RouterLink}
            to={isLearningTrainingPageReact ? trainingTypeRoute : trainingView(training.identifier)}
            className={element('box')}
            {...get({ element: 'list-element', action: 'open-learning-training' })}
        >
            <div
                className={classnames(element(container), {
                    [element('due-date-flag')]: training.dueDate && !training.isSandbox,
                })}
            >
                {training.dueDate ? (
                    <ImageBlock
                        alt=""
                        image={training.cover}
                        thumbnailProps={{
                            aspectRatio: 'horizontal',
                        }}
                        actions={
                            training.isSandbox ? (
                                <Flag
                                    className={background('light', 'N')}
                                    color="yellow"
                                    icon={mdiAccountCog}
                                    label={translateKey(MICROAPP.TEST_LABEL)}
                                />
                            ) : (
                                <Flag
                                    className={background('light', 'N')}
                                    color={differenceInDays < 0 ? 'red' : 'yellow'}
                                    icon={differenceInDays < 0 ? mdiCalendarClock : mdiCalendarAlert}
                                    label={
                                        differenceInDays === 0
                                            ? translateKey(GLOBAL.TODAY)
                                            : translateAndReplace(
                                                  pluralize(GLOBAL.OCCURRENCES_DAY, differenceInDaysAbsolue),
                                                  {
                                                      NUMBER: differenceInDaysAbsolue,
                                                  },
                                              )
                                    }
                                />
                            )
                        }
                    />
                ) : (
                    <ImageBlock
                        alt=""
                        image={training.cover}
                        thumbnailProps={{
                            aspectRatio: 'horizontal',
                        }}
                        actions={
                            training.isSandbox ? (
                                <Flag
                                    className={background('light', 'N')}
                                    color="yellow"
                                    icon={mdiAccountCog}
                                    label={translateKey(MICROAPP.TEST_LABEL)}
                                />
                            ) : (
                                isRecommendedFlagDisplayed && (
                                    <Flag
                                        className={background('light', 'N')}
                                        color="blue"
                                        icon={mdiTrophyAward}
                                        label={translateKey(GLOBAL.RESULT_IS_RECOMMENDED)}
                                    />
                                )
                            )
                        }
                    />
                )}
                {isProgressBarDisplayed && (
                    <ProgressionBar
                        className={element('progress-bar')}
                        colorDone={{ colorPalette: 'primary', colorVariant: 'L1' }}
                        colorRemaining={{ colorPalette: 'primary', colorVariant: 'L4' }}
                        percents={training.progress}
                        maxWidth={PROGRESS_BAR_MAX_WIDTH}
                        variant="squared"
                    />
                )}
            </div>
            <FlexBox
                className={element('text', [
                    isButtonDisplayed && margin('top', 'big'),
                    !isButtonDisplayed && margin('top', 'tiny'),
                    !isButtonDisplayed && padding(undefined, 'regular'),
                ])}
            >
                <Flag
                    className={backgroundTheme}
                    color={colorTheme}
                    icon={TRAINING_TYPE_CONFIG.icon[trainingType]}
                    label={translateKey(TRAINING_TYPE_CONFIG.label[trainingType])}
                    style={{ height: 'auto' }}
                />
                <FlexBox
                    className={classnames(
                        isButtonDisplayed && margin('bottom', 'regular'),
                        isButtonDisplayed && margin('top', 'regular'),
                    )}
                >
                    <Tooltip label={title}>
                        <Text color={colorTheme} truncate={{ lines: 2 }} typography="custom-title5" as="p">
                            {title}
                        </Text>
                    </Tooltip>
                </FlexBox>
                <Tooltip label={category}>
                    <Text color={colorTheme} truncate typography="custom-body" as="p">
                        {category}
                    </Text>
                </Tooltip>
            </FlexBox>
            {isButtonDisplayed && (
                <Button className={margin('top', 'big')} emphasis={Emphasis.medium} fullWidth>
                    {translateKey(TRAININGS.LAUNCH_TRAINING)}
                </Button>
            )}
        </Link>
    );
};
