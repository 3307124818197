import isEmpty from 'lodash/isEmpty';

import BaseApi, { API_VERSION, type ServerListRequest, cleanParams } from '@lumapps/base-api';
import { type CONNECTOR_PROTOCOL, type CONNECTOR_STATUS } from '@lumapps/marketplace/sdk';
import { ObjectValues } from '@lumapps/utils/types/ObjectValues';

import type {
    CONNECTOR_SORT,
    Connector,
    ConnectorAuthorizeResponse,
    ConnectorListResponse,
    FormConnector,
    FormPresetConnector,
    TemplateValues,
} from '../types';

/**
 * Base API instance for connectors.
 */
export const connectorsAPI = new BaseApi({ path: 'connectors', version: API_VERSION.v2 });

/**
 * Create a new connector
 *
 * @param {FormConnector} params
 * @returns {Promise<Connector>}
 */
export const createConnector = async (params: FormConnector): Promise<Connector> => {
    const { data: connector } = await connectorsAPI.post<Connector>('', { ...cleanParams(params) });

    return connector;
};

export interface EditConnectorParams {
    /** Id of the connector being edited */
    connectorId: string;
    /** New values for the connector being edited */
    params: FormConnector;
}

/**
 * Edit a connector by it's id
 *
 * @param {EditConnectorParams} params
 * @returns {Promise<Connector>}
 */
export const editConnector = async ({ connectorId, params }: EditConnectorParams): Promise<Connector> => {
    const { data: connector } = await connectorsAPI.put<Connector>(connectorId, { ...cleanParams(params) });

    return connector;
};

/**
 * Fetch a connector by it's id.
 *
 * @param {Connector['id']} connectorId
 * @param {AbortSignal | undefined} signal
 * @returns {Promise<Connector>}
 */
export const getConnector = async (connectorId: Connector['id'], signal?: AbortSignal): Promise<Connector> => {
    const { data: connector } = await connectorsAPI.get<Connector>(connectorId, { signal });

    return connector;
};

/**
 * Delete a connector by its id.
 *
 * @param {Connector['id']} connectorId
 * @returns {BaseApiPromise}
 */
export const deleteConnector = (connectorId: Connector['id']) => connectorsAPI.delete(connectorId);

export interface GetConnectorListParams extends ServerListRequest {
    hasPartner?: boolean;
    name?: string;
    partnerIds?: string;
    protocol?: ObjectValues<typeof CONNECTOR_PROTOCOL>;
    providerType?: string;
    sort?: CONNECTOR_SORT;
    statuses?: ObjectValues<typeof CONNECTOR_STATUS>[];
    extensionId?: string;
}
/**
 * Fetch the connector list.
 *
 * @param {GetConnectorListParams} params
 * @param {string | undefined} fetchKey
 * @param {AbortSignal | undefined} signal
 * @returns {ConnectorListResponse}
 */
export const getConnectorList = async (params: GetConnectorListParams, fetchKey?: string, signal?: AbortSignal) => {
    const parameters = {
        ...params,
        name: !isEmpty(params.name) ? params.name : undefined,
        statuses: params.statuses?.length ? params.statuses.join(',') : undefined,
    };

    const { data: connectorList } = await connectorsAPI.get<ConnectorListResponse>(
        '',
        { params: cleanParams(parameters), signal },
        undefined,
        true,
        fetchKey,
    );

    return connectorList;
};

/**
 * Cancel a `getConnectorList` call by `fetchKey`
 *
 * @param {string} fetchKey
 */
export const cancelGetConnectorList = (fetchKey: string) => connectorsAPI.cancel('', undefined, fetchKey);

export interface EditConnectorTemplateValuesParams {
    /** Id of the connector being edited */
    connectorId: Connector['id'];
    /** New values for the connector template values */
    params: FormPresetConnector;
}

/**
 * Edit a template values from the connector id
 *
 * @param {EditConnectorTemplateValuesParams} params
 * @returns {Promise<TemplateValues>}
 */
export const editConnectorTemplateValues = async ({
    connectorId,
    params,
}: EditConnectorTemplateValuesParams): Promise<TemplateValues> => {
    const { data: templateValues } = await connectorsAPI.put<TemplateValues>(`${connectorId}/template-values`, {
        ...cleanParams(params),
    });

    return templateValues;
};

/**
 * Get authorize URL
 *
 * @param {Connector['id']} connectorId
 * @returns {Promise<ConnectorAuthorizeResponse>}
 */
export const getAuthorizeUrl = (connectorId: Connector['id']) =>
    connectorsAPI.get<ConnectorAuthorizeResponse>(`${connectorId}/authorize`);

/**
 * De-authorize connector
 *
 * @param {Connector['id']} connectorId
 * @returns {Promise<ConnectorAuthorizeResponse>}
 */
export const deauthorizeConnector = (connectorId: Connector['id']) =>
    connectorsAPI.post<ConnectorAuthorizeResponse>(`${connectorId}/deauthorize`, {});

/**
 * Validate and build a client secret compatible with a connector from a given file content.
 *
 * @param {string} fileContent
 * @returns {Promise<string>}
 */
export const getClientSecret = async (fileContent: string) => {
    const { data: clientSecret } = await connectorsAPI.post<{ privateKey: string }>('client-secret', fileContent, {
        headers: {
            'Content-Type': 'text/plain',
        },
    });

    return clientSecret.privateKey;
};
