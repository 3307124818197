import React from 'react';

import { paddings } from '@lumapps/classnames';
import type { AuthorDetails } from '@lumapps/contents/types';
import { mdiAlert, mdiClose } from '@lumapps/lumx/icons';
import { CommentBlock, Dialog, FlexBox, Heading, Icon, IconButton, Message, Toolbar } from '@lumapps/lumx/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';
import { getUserFullName } from '@lumapps/user/utils/getUserFullName';
import { getUserProfilePictureUrl } from '@lumapps/user/utils/getUserProfilePicture';
import { formatFromNow } from '@lumapps/utils/date/formatFromNow';

import { DESIGNER } from '../../keys';

interface RefusedReasonDialogProps {
    isOpen: boolean;
    closeDialog: () => void;
    refusedReason?: string;
    updatedBy: AuthorDetails;
    updatedAt: string;
}

export const RefusedReasonDialog: React.FC<RefusedReasonDialogProps> = ({
    isOpen,
    closeDialog,
    refusedReason,
    updatedBy,
    updatedAt,
}) => {
    const { translateKey } = useTranslate();

    return (
        <Dialog size="tiny" isOpen={isOpen}>
            <header>
                <Toolbar
                    before={<Icon icon={mdiAlert} size="s" colorVariant="N" color="red" />}
                    after={
                        <IconButton
                            emphasis="low"
                            label={translateKey(GLOBAL.CLOSE)}
                            onClick={closeDialog}
                            icon={mdiClose}
                        />
                    }
                    label={
                        <Heading as="h2" typography="title" truncate>
                            {translateKey(DESIGNER.REVISION_REQUESTED)}
                        </Heading>
                    }
                />
            </header>
            <FlexBox
                orientation="vertical"
                gap="big"
                className={paddings([
                    { direction: 'horizontal', size: 'huge' },
                    { direction: 'vertical', size: 'big' },
                ])}
            >
                <Message kind="info" hasBackground>
                    {translateKey(DESIGNER.REFUSED_HELPER_MESSAGE)}
                </Message>
                <CommentBlock
                    isOpen
                    text={refusedReason}
                    name={getUserFullName(updatedBy)}
                    date={formatFromNow({
                        date: updatedAt,
                        thresholdDisplayFullDate: 1,
                        dateStringFormat: 'lll',
                    })}
                    avatarProps={{
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        image: getUserProfilePictureUrl({ id: updatedBy.uid!, customer: updatedBy.customer }),
                        alt: getUserFullName(updatedBy),
                    }}
                />
            </FlexBox>
        </Dialog>
    );
};
