import React from 'react';

import {
    getCurrentContent,
    isContributionAlphaFeatureEnabled,
    isNewContributionExperienceEnabled,
    isNewSimpleTemplateFeatureEnabled,
} from '@lumapps/contents/ducks/selectors';
import { Content, LegacyContentStatus } from '@lumapps/contents/types';
import { useDataAttributes } from '@lumapps/data-attributes';
import { designerContentPreview } from '@lumapps/designer-preview/routes';
import { inputLanguageSelector } from '@lumapps/languages';
import { Menubar } from '@lumapps/lumx-menu/components/Menubar';
import { mdiEye } from '@lumapps/lumx/icons';
import { AlertDialog, Button, IconButton, Kind, Text } from '@lumapps/lumx/react';
import { useSelector } from '@lumapps/redux/react';
import { useResponsive } from '@lumapps/responsive';
import { useRouter } from '@lumapps/router';
import { GLOBAL, useTranslate } from '@lumapps/translations';
import { isLayoutEnabled } from '@lumapps/widget-layout/ducks/selectors';

import { SCOPE } from '../../constants';
import { CONTENT_PREVIEW } from '../../keys';

export interface ContentPreviewButtonProps {
    /**
     * Content to preview
     */
    content?: Content;
    /**
     * Save content as draft (needed before previewing)
     */
    saveContentDraft?: () => Promise<Content>;
}

/**
 * Is preview unavailable alert dialog open.
 * We need it as a global state because angularjs breaks our internal state on rerender...
 */
let isPreviewUnavailableAlertDialogOpen = false;

const usePreviewUnavailableAlert = () => {
    const [isOpen, setOpen] = React.useState(isPreviewUnavailableAlertDialogOpen);
    const open = React.useCallback(() => {
        isPreviewUnavailableAlertDialogOpen = true;
        setOpen(isPreviewUnavailableAlertDialogOpen);
    }, []);
    const close = React.useCallback(() => {
        isPreviewUnavailableAlertDialogOpen = false;
        setOpen(isPreviewUnavailableAlertDialogOpen);
    }, []);
    return { isOpen, open, close };
};

/**
 * Content preview button
 */
export const ContentPreviewButton: React.FC<ContentPreviewButtonProps> = ({ saveContentDraft }) => {
    const { redirect } = useRouter();
    const { translateKey } = useTranslate();
    const { get } = useDataAttributes(SCOPE);
    const { isMobile } = useResponsive();

    const content = useSelector(getCurrentContent);
    const inputLanguage = useSelector(inputLanguageSelector);

    const isLayoutFFEnabled = useSelector(isLayoutEnabled);
    const isNewContributionExperienceFFEnabled = useSelector(isNewContributionExperienceEnabled);
    const isContributionAlphaEnabled = useSelector(isContributionAlphaFeatureEnabled);
    const isNewSimpleTemplateEnabled = useSelector(isNewSimpleTemplateFeatureEnabled);

    const isSimpleTemplateMode = !!content?.template?.basicMode || isNewContributionExperienceFFEnabled;
    const isEnabled = isLayoutFFEnabled && (!isSimpleTemplateMode || isNewSimpleTemplateEnabled);

    // We display as menu item in last version of new contribution experience (behind the alpha FF for now)
    const isMenuBarItem = isNewContributionExperienceFFEnabled && isContributionAlphaEnabled;
    // We display as an icon button in simple template responsive and in legacy designer.
    const isIconButton = !isMenuBarItem && (isMobile || !isSimpleTemplateMode);
    // In any other case (ie. desktop + simple template) we display a full button
    const isButton = !isMenuBarItem && !isIconButton;

    const previewUnavailable = usePreviewUnavailableAlert();
    const onClick = React.useCallback(async () => {
        let contentToPreview = content;
        // Save content as draft (if not "to validate")
        if (saveContentDraft && content?.status !== LegacyContentStatus.toValidate) {
            contentToPreview = await saveContentDraft();
        }

        if (!contentToPreview?.template?.isV2Compatible) {
            previewUnavailable.open();
        } else if (contentToPreview) {
            redirect(designerContentPreview(contentToPreview.id, inputLanguage), {
                // in a new tab
                openInNewTab: true,
            });
        }
    }, [content, saveContentDraft, previewUnavailable, redirect, inputLanguage]);

    if (!isEnabled) {
        return null;
    }

    return (
        <>
            {isButton && (
                <Button emphasis="low" leftIcon={mdiEye} onClick={onClick} {...get({ element: 'designer-button' })}>
                    {translateKey(GLOBAL.PREVIEW)}
                </Button>
            )}
            {isIconButton && (
                <IconButton
                    emphasis="low"
                    theme={isSimpleTemplateMode ? 'light' : 'dark'}
                    icon={mdiEye}
                    label={translateKey(GLOBAL.PREVIEW)}
                    onClick={onClick}
                    {...get({ element: 'designer-button' })}
                />
            )}
            {isMenuBarItem && (
                <Menubar.Item
                    tooltipProps={{
                        placement: 'left',
                    }}
                    onClick={onClick}
                    icon={mdiEye}
                >
                    {translateKey(GLOBAL.PREVIEW)}
                </Menubar.Item>
            )}
            <AlertDialog
                kind={Kind.warning}
                isOpen={previewUnavailable.isOpen}
                title={translateKey(CONTENT_PREVIEW.PREVIEW_UNAVAILABLE)}
                confirmProps={{
                    onClick: previewUnavailable.close,
                    label: translateKey(GLOBAL.CLOSE),
                }}
            >
                <Text as="p" typography="body1">
                    {translateKey(CONTENT_PREVIEW.PREVIEW_UNAVAILABLE_DESCRIPTION)}
                </Text>
            </AlertDialog>
        </>
    );
};
