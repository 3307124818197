import React from 'react';

import { ContentPublicationStatus } from '@lumapps/contents/constants';
import { useDataAttributes } from '@lumapps/data-attributes';
import { Menu } from '@lumapps/lumx-menu/components/Menu';
import {
    mdiMenuDown,
    mdiArchiveArrowDownOutline,
    mdiArrowDown,
    mdiArrowUp,
    mdiClockEnd,
    mdiClose,
    mdiFileEditOutline,
} from '@lumapps/lumx/icons';
import { IconButton } from '@lumapps/lumx/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';

import { CONTENT_EDITOR_TOPBAR_SCOPE } from '../../constants';
import { DESIGNER } from '../../keys';

interface SecondaryActionsMenuProps {
    archiveContent: () => void;
    publicationStatus: ContentPublicationStatus;
    canArchive: boolean;
    isSavedOnce: boolean;
    canPublish: boolean;
    isAwaitingValidation: boolean;
    setContentToDraft: () => void;
    publishContent: () => void;
    requestRevision: () => void;
}

export const SecondaryActionsMenu: React.FC<SecondaryActionsMenuProps> = ({
    archiveContent,
    isSavedOnce,
    publicationStatus,
    canArchive,
    canPublish,
    isAwaitingValidation,
    setContentToDraft: setToDraft,
    publishContent,
    requestRevision,
}) => {
    const { translateKey } = useTranslate();
    const { get } = useDataAttributes(CONTENT_EDITOR_TOPBAR_SCOPE);

    const displayPublishNowOption = publicationStatus === 'scheduled' && canPublish;
    const displayEditSchedulingOption = publicationStatus === 'scheduled' && canPublish;
    const displayScheduleOption =
        (publicationStatus === 'draft' || publicationStatus === 'expired') && isSavedOnce && canPublish;
    const displayUnpublishOption = publicationStatus === 'published' && canPublish;
    const displayRefuseOption = isAwaitingValidation;
    const displayUnscheduleOption = publicationStatus === 'scheduled' && canPublish;
    const displayArchiveOption = canArchive;

    if (
        !displayArchiveOption &&
        !displayUnpublishOption &&
        !displayPublishNowOption &&
        !displayEditSchedulingOption &&
        !displayScheduleOption &&
        !displayRefuseOption &&
        !displayUnscheduleOption
    ) {
        return null;
    }

    return (
        <Menu.Trigger>
            <Menu.TriggerButton
                as={IconButton}
                icon={mdiMenuDown}
                label={translateKey(GLOBAL.MORE_ACTIONS)}
                {...get({ element: 'publication-workflow', action: 'show-more-options' })}
            />
            <Menu>
                <Menu.Section>
                    {displayPublishNowOption && (
                        <Menu.Item
                            onClick={publishContent}
                            icon={mdiArrowUp}
                            {...get({ element: 'publication-workflow', action: 'publish-now' })}
                        >
                            {translateKey(DESIGNER.PUBLISH_NOW_LABEL)}
                        </Menu.Item>
                    )}
                    {displayEditSchedulingOption && (
                        <Menu.Item
                            icon={mdiClockEnd}
                            {...get({ element: 'publication-workflow', action: 'edit-scheduling' })}
                        >
                            {translateKey(DESIGNER.EDIT_SCHEDULING)}
                        </Menu.Item>
                    )}
                    {displayScheduleOption && <Menu.Item icon={mdiClockEnd}>{translateKey(GLOBAL.SCHEDULE)}</Menu.Item>}
                </Menu.Section>
                <Menu.Section>
                    {displayRefuseOption && (
                        <Menu.Item
                            onClick={requestRevision}
                            icon={mdiFileEditOutline}
                            {...get({ element: 'publication-workflow', action: 'request-revision' })}
                        >
                            {translateKey(DESIGNER.REFUSE_CONTENT_PUBLICATION_LABEL)}
                        </Menu.Item>
                    )}
                    {displayUnscheduleOption && (
                        <Menu.Item
                            onClick={publishContent}
                            icon={mdiClose}
                            {...get({ element: 'publication-workflow', action: 'unschedule' })}
                        >
                            {translateKey(DESIGNER.UNSCHEDULE)}
                        </Menu.Item>
                    )}
                    {displayUnpublishOption && (
                        <Menu.Item
                            onClick={setToDraft}
                            icon={mdiArrowDown}
                            {...get({ element: 'publication-workflow', action: 'unpublish' })}
                        >
                            {translateKey(DESIGNER.UNPUBLISH)}
                        </Menu.Item>
                    )}
                    {displayArchiveOption && (
                        <Menu.Item
                            icon={mdiArchiveArrowDownOutline}
                            onClick={archiveContent}
                            {...get({ element: 'publication-workflow', action: 'archive' })}
                        >
                            {translateKey(GLOBAL.ARCHIVE)}
                        </Menu.Item>
                    )}
                </Menu.Section>
            </Menu>
        </Menu.Trigger>
    );
};
