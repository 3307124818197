/* istanbul ignore file */
export enum SHARE {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=45134872
     */
    MESSAGE_PLACEHOLDER = 'FRONT.SHARE_POSTS_TEAMS.DIALOG.MESSAGE.PLACEHOLDER',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=615764275
     */
    COPY_LINK_FROM = 'FRONT.SHARE.COPY_LINK_FROM',
}
