import { mdiEye, mdiPaletteAdvanced, mdiPencilOutline } from '@lumapps/lumx/icons';
import { GLOBAL } from '@lumapps/translations';

import { DESIGNER } from './keys';
import type { ContentPropertyToContentSettingFieldsType } from './types';

export enum CONTRIBUTION_MODES {
    READER = 'READER_MODE',
    WRITER = 'WRITER_MODE',
    DESIGNER = 'EXPERT_MODE',
}

/** List of the field names of a content scheduled / expiration date */
export const CONTENT_DATE_FIELD_NAMES = {
    SCHEDULED: 'scheduled-date',
    EXPIRED: 'expired-date',
} as const;

export const CONTENT_PUBLICATION_SOURCE_TYPE = {
    SCHEDULE: 'schedule',
    PUBLISH: 'publish',
} as const;

/** All content settings available */
export const CONTENT_SETTINGS_FIELD_NAMES = {
    TITLE: 'title',
    URL: 'url',
    EXTERNAL_LINK: 'external-link',
    VISIBLE_BY: 'visible-by',
    HIGHLIGHTED_FOR: 'highlighted-for',
    EDITORS: 'editors',
    TAGS: 'tags',
    METADATA: 'metadata',
    NOTIFIED_GROUPS: 'notified-groups',
    WRITING_ON_BEHALF: 'writing-on-behalf',
    SCHEDULE_DATE: 'schedule-date',
    EXPIRATION_DATE: 'expiration-date',
    FEATURED: 'featured',
    CONTENT_HTML_CLASS: 'content-html-class',
    CONTENT_HTML_ID: 'content-html-id',
} as const;

/** Content settings available for user with only WRITER permission */
export const WRITER_MODE_SETTINGS = [
    CONTENT_SETTINGS_FIELD_NAMES.TITLE,
    CONTENT_SETTINGS_FIELD_NAMES.URL,
    CONTENT_SETTINGS_FIELD_NAMES.VISIBLE_BY,
    CONTENT_SETTINGS_FIELD_NAMES.HIGHLIGHTED_FOR,
    CONTENT_SETTINGS_FIELD_NAMES.TAGS,
    CONTENT_SETTINGS_FIELD_NAMES.METADATA,
    CONTENT_SETTINGS_FIELD_NAMES.SCHEDULE_DATE,
    CONTENT_SETTINGS_FIELD_NAMES.EXPIRATION_DATE,
    CONTENT_SETTINGS_FIELD_NAMES.FEATURED,
] as const;

export const CONTENT_PROPERTY_TO_CONTENT_SETTING_FIELD_NAME: ContentPropertyToContentSettingFieldsType = {
    title: CONTENT_SETTINGS_FIELD_NAMES.TITLE,
    startDate: CONTENT_SETTINGS_FIELD_NAMES.SCHEDULE_DATE,
    endDate: CONTENT_SETTINGS_FIELD_NAMES.EXPIRATION_DATE,
    writerDetails: CONTENT_SETTINGS_FIELD_NAMES.WRITING_ON_BEHALF,
    metadata: CONTENT_SETTINGS_FIELD_NAMES.METADATA,
    feedKeys: CONTENT_SETTINGS_FIELD_NAMES.VISIBLE_BY,
    featuredFeedKeys: CONTENT_SETTINGS_FIELD_NAMES.HIGHLIGHTED_FOR,
    customContentTypeTags: CONTENT_SETTINGS_FIELD_NAMES.TAGS,
    editors: CONTENT_SETTINGS_FIELD_NAMES.EDITORS,
    notifyFeedKeys: CONTENT_SETTINGS_FIELD_NAMES.NOTIFIED_GROUPS,
} as const;
export const CONTRIBUTION_MODE_MAP: Record<CONTRIBUTION_MODES, { key: string; icon?: string; description?: string }> = {
    [CONTRIBUTION_MODES.READER]: {
        key: DESIGNER.READER_MODE_LABEL,
        icon: mdiEye,
    },
    [CONTRIBUTION_MODES.DESIGNER]: {
        key: GLOBAL.DESIGNER,
        icon: mdiPaletteAdvanced,
        description: DESIGNER.DESIGNER_MODE_DESCRIPTION,
    },
    [CONTRIBUTION_MODES.WRITER]: {
        key: DESIGNER.WRITER_MODE_LABEL,
        icon: mdiPencilOutline,
        description: DESIGNER.WRITER_MODE_DESCRIPTION,
    },
};

export const SELECTABLE_CONTRIBUTION_MODES = {
    [CONTRIBUTION_MODES.DESIGNER]: CONTRIBUTION_MODE_MAP[CONTRIBUTION_MODES.DESIGNER],
    [CONTRIBUTION_MODES.WRITER]: CONTRIBUTION_MODE_MAP[CONTRIBUTION_MODES.WRITER],
};

export const TOPBAR_CLASSNAME = 'content-editor-topbar';

export const CONTENT_EDITOR_TOPBAR_SCOPE = 'content-editor-topbar';
