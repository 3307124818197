import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import { Size, Theme } from '@lumapps/lumx/react';
import { MovingFocusProvider } from '@lumapps/moving-focus';
import { TreeOpenStateContext } from '@lumapps/utils/components/TreeOpenStateContext';

import { MenuContext } from '../Menu/context';
import { MenubarProps } from './types';
import { useMenubar } from './useMenubar';

import './index.scss';

const CLASSNAME = 'lumx-menubar';

/**
 * Menubar using the WAI-ARIA menubar pattern.
 *
 * @family Menus
 */
export const Menubar = React.forwardRef<HTMLElement, MenubarProps>((props, ref) => {
    const {
        className,
        children,
        theme = Theme.light,
        size = Size.m,
        ariaLabel,
        orientation: menuBarOrientation = 'horizontal',
        ...forwardedProps
    } = props;

    const { block } = useClassnames(CLASSNAME);
    const { orientation, menuContext, menuOpenState, onFocus } = useMenubar(props);

    return (
        <TreeOpenStateContext.Provider value={menuOpenState}>
            <MenuContext.Provider value={menuContext}>
                <MovingFocusProvider options={{ direction: orientation, loopAround: true }}>
                    <ul
                        ref={ref as React.Ref<HTMLUListElement>}
                        role="menubar"
                        className={block(
                            {
                                [`theme-${theme}`]: !!theme,
                                [`size-${size}`]: !!size,
                                [`orientation-${menuBarOrientation}`]: !!menuBarOrientation,
                            },
                            className,
                        )}
                        aria-label={ariaLabel}
                        aria-orientation={orientation}
                        {...forwardedProps}
                        onFocus={onFocus}
                    >
                        {children}
                    </ul>
                </MovingFocusProvider>
            </MenuContext.Provider>
        </TreeOpenStateContext.Provider>
    );
});
